import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "../login.css";
import { popup } from "../../vanilla-functions/model";
import LoginCountdown from "./login-countdown";
import AppContext from "../../context/app-context";
import { BiArrowBack } from "react-icons/bi";

const theCode = [];

const LoginCodeScreen = function ({
  display,
  setStage,
  email,
  stage,
  code,
  setCode,
  setCodeSentFromServer,
  parentLogin,
  deadline,
  setDeadline,
}) {
  const [disableBtn, setDisableBtn] = useState(false);

  const [codeExpired, setCodeExpired] = useState(false);

  const { setLoggedIn, backendServer, setIsLoading, setUsersData } = useContext(AppContext);

  useEffect(() => {
    if (code.length === 4) setDisableBtn(false);
    else setDisableBtn(true);
  });

  function handleCodeChange(e) {
    if (!e.target.value)
      document
        .querySelector("#codeboxcontainer")
        ?.children[Number(e.target.dataset.key) - 1]?.children[0]?.focus();
    else
      document
        .querySelector("#codeboxcontainer")
        ?.children[Number(e.target.dataset.key) + 1]?.children[0]?.focus();

    theCode[e.target.dataset.key] = e.target.value;

    setCode(theCode.join(""));
  }

  const onFinish = () => {
    setCodeExpired(true);
    setCodeSentFromServer("");
  };

  async function login() {
    try {
      setIsLoading(true);

      const codeVerify = await (
        await fetch(`${backendServer}/any/token/${email}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ token: code }),
          credentials: "include",
        })
      ).json();

      if (codeVerify.status === "Internal server error") throw new Error(codeVerify.message);

      const returned = await (
        await fetch(`${backendServer}/vendor/login`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: email, token: code }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      localStorage.setItem("vmsusername", returned.data.username);
      localStorage.setItem("vmsemail", returned.data.email);
      localStorage.setItem("vmstoken", returned.data.token);

      const data = await (
        await fetch(`${backendServer}/user/get?email=${returned.data.email}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("vmstoken"),
          },
        })
      ).json();

      setUsersData(data.data);

      setIsLoading(false);

      setLoggedIn(true);

      popup(`Welcome back, ${data.data.first_name}`);

      document.location.hash = "Dashboard";
    } catch (err) {
      popup(err.message || "Something went wrong");
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div className="login-cont">
      <div
        className="flex-row align-row-left cursor"
        style={{ width: "55%", marginTop: -40, marginBottom: 30 }}
        onClick={() => setStage("login")}
      >
        <BiArrowBack size={20} />
      </div>

      <div className="flex-row align-row-left" style={{ width: "55%" }}>
        <p className="small boldText">
          We've sent a code to your <span>{email}</span> to approve your login
        </p>
      </div>
      <div id="codeboxcontainer" className="flex-row" style={{ width: "55%" }}>
        <div className="code-boxes">
          <input
            maxLength="1"
            data-key={0}
            placeholder="*"
            type="text"
            onChange={handleCodeChange}
          />
        </div>
        <div className="code-boxes">
          <input
            maxLength="1"
            data-key={1}
            placeholder="*"
            type="text"
            onChange={handleCodeChange}
          />
        </div>
        <div className="code-boxes">
          <input
            maxLength="1"
            data-key={2}
            placeholder="*"
            type="text"
            onChange={handleCodeChange}
          />
        </div>
        <div className="code-boxes">
          <input
            maxLength="1"
            data-key={3}
            placeholder="*"
            type="text"
            onChange={handleCodeChange}
          />
        </div>
      </div>

      <button disabled={disableBtn} onClick={login} className="themeBtn login-btn">
        Confirm Login
      </button>

      <div className="flex-row" style={{ justifyContent: "flex-end", width: "55%", gap: 5 }}>
        <LoginCountdown
          deadline={deadline}
          setDeadline={setDeadline}
          stage={stage}
          onFinish={onFinish}
        />
        <p
          onClick={() => parentLogin()}
          className="small removemargin cursor"
          style={{ color: "#5334D7" }}
        >
          Resend Code
        </p>
      </div>

      {codeExpired && (
        <p className="small">
          Code Expired{" "}
          <span
            onClick={() => setStage("login")}
            className="cursor"
            style={{ textDecoration: "underline", textDecorationColor: "#34204c" }}
          >
            Request Another
          </span>
        </p>
      )}
    </div>
  );
};

export default LoginCodeScreen;
