import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { IUserData } from "./main-app-context.type";
import AppContext from '../app-context'


type IMainContext = {
    userData: IUserData;
    getSignedAwsUrl: (fileName: string, bucketName: string) => any;
    setIsLoading: (val: boolean) => void;
    emsToken: string;
    backendServerUrl: string
    updateUsersData: () => void;
    productActiveScreenIndex: number
    setProductActiveScreenIndex: React.Dispatch<React.SetStateAction<number>>;
}

const MainAppContext = createContext<IMainContext>({
    userData: {} as IUserData, 
    getSignedAwsUrl: () => {},
    setIsLoading: () => {},
    emsToken: '',
    backendServerUrl: '',
    updateUsersData: () => {},
    productActiveScreenIndex: 0,
    setProductActiveScreenIndex: () => {}
});

type IMainAppContextProvider = {
    children: ReactNode
}


const MainAppContextProvider = ({children}: IMainAppContextProvider ) => {
    const { usersData, getSignedAwsUrl, setIsLoading, backendServer, updateUsersData} = useContext(AppContext);

    const [userMainData, setUserMainData] = useState<IUserData>(usersData as IUserData);

    const [productActiveScreenIndex, setProductActiveScreenIndex] = useState(0)


    useEffect(() => {
        setUserMainData(usersData)
    }, [usersData])


    const getEmsToke = () => {
        const token = localStorage.getItem("vmstoken");
        if(token) return token;
        return '';
    }


    
    return (
        <MainAppContext.Provider value={{
            userData: userMainData,
            getSignedAwsUrl, 
            setIsLoading,
            emsToken: getEmsToke(),
            backendServerUrl: backendServer,
            updateUsersData,
            productActiveScreenIndex,
            setProductActiveScreenIndex
            }}>
            {children}
        </MainAppContext.Provider>
    )
}

export const useMainAppContext = () => useContext(MainAppContext);

export default MainAppContextProvider;