import React, { useEffect, useState, useContext } from "react";
import "./ProductDashboard.css";
import StatsCard from "../../../components/statCard/StatsCard";
import SearchInput from "../../../components/searchInput/SearchInput";
import { useMainAppContext } from "../../../context/appContext/MainAppContext";
import { ProductDto, calculateProductStats } from "../dto/product.dto";
import ProductList from "./ProductList/ProductList";
import Button from "../../../components/button/Button";
import { LuFolderCheck } from "react-icons/lu";
import { GiBattery25 } from "react-icons/gi";
import { PiHandbagSimpleBold } from "react-icons/pi";
import { MdFolderOpen } from "react-icons/md";
import ProductDetails from "../productDetails/ProductDetails";
import ProductEdit from "../productEdit/ProductEdit";
import { popup } from "../../../vanilla-functions/model";
import ProductAdd from "../productAdd/ProductAdd";
import AppContext from "../../../context/app-context";

type Props = {
  onNavigate: (s: number) => void;
  setCurProduct: (s: any) => void;
};

const ProductDashboardFistScreen = ({ onNavigate, setCurProduct }: Props) => {
  const { emsToken, backendServerUrl, getSignedAwsUrl, setIsLoading, userData } =
    useMainAppContext();
  const { usersData } = useContext(AppContext);

  const [products, setProduct] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>(products);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [editedForm, setEditedForm] = useState<any[]>([]);

  const [status, setStatus] = useState({ all: 0, active: 0, lowStock: 0, sold: 0 });

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const url = `${backendServerUrl}/vms/product/find/basic?vendor=${usersData?.vendor?._id}`;
      setIsLoading(true);
      const _res = await (
        await fetch(url, {
          headers: {
            token: emsToken,
          },
          method: "GET",
        })
      ).json();

      const { status, message, data } = _res;
      setIsLoading(false);
      if (status && status.toLowerCase().includes("error")) {
        message && popup(message);
        setIsLoading(false);
        return;
      }

      setOriginalData(data);

      const temp: ProductDto[] = [];

      data.forEach((el: any, i: number) => {
        temp.push(new ProductDto(el));
      });

      // console.log('Formared:::', temp)
      setProduct(temp);
      setFilteredData(temp);
      const stats = calculateProductStats(temp);
      setStatus({
        all: stats.totalProduct,
        active: stats.activeProduct,
        lowStock: stats.lowOnStock,
        sold: stats.soldOut,
      });
    } catch (error: any) {
      popup(error.message);
      setIsLoading(false);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  function filterProducts(type: string) {
    const temp: ProductDto[] = [];

    if (type === "all") {
      originalData.forEach((el: any, i: number) => {
        temp.push(new ProductDto(el));
      });
    }

    if (type === "active") {
      originalData
        .filter((e: any) => e.is_live)
        .forEach((el: any, i: number) => {
          temp.push(new ProductDto(el));
        });
    }

    if (type === "low") {
      originalData
        .filter((e: any) => e.quantityAvailable <= 10 && e.quantityAvailable > 0)
        .forEach((el: any, i: number) => {
          temp.push(new ProductDto(el));
        });
    }

    if (type === "sold") {
      originalData
        .filter((e: any) => e.quantityAvailable < 1)
        .forEach((el: any, i: number) => {
          temp.push(new ProductDto(el));
        });
    }

    setProduct(temp);
    setFilteredData(temp);
  }


  const handleProductMassUpdate = async () => {
    const url = `${backendServerUrl}/product/mass/update`;
    const payload: any[] = [];
    editedForm.forEach((el) => {
      payload.push({
        _id: el.id,
        minimumPrice: el.price || el.minimumPrice,
        is_live: el.is_live || el.live,
        quantityAvailable: el.quantityAvailable,
      });
    });

    try {
      setIsLoading(true);
      const res = await (
        await fetch(url, {
          headers: {
            token: emsToken,
            "content-type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify(payload),
        })
      ).json();

      const { status, message } = res;

      if (status.toLowerCase().includes("error")) {
        message && popup(message);
        setIsLoading(false);
      }
      setEditedForm([]);
      popup("Data updated successfully!");
      setIsLoading(false);
      await getProducts();
    } catch (error: any) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="product-dashboard">
      {/* stat wrapper start */}
      <div className="product-dashboard-stat-wrapper">
        <StatsCard
          onClick={() => filterProducts("all")}
          count={status.all}
          label={"All Products"}
          icon={(className) => <MdFolderOpen className={className} />}
          styleStr="all"
        />
        <StatsCard
          onClick={() => filterProducts("active")}
          count={status.active}
          label={"Active Products"}
          icon={(className) => <LuFolderCheck className={className} />}
          styleStr="active"
        />
        <StatsCard
          onClick={() => filterProducts("low")}
          count={status.lowStock}
          label={"Low on Stock"}
          icon={(className) => <GiBattery25 className={className} />}
          styleStr="low"
        />
        <StatsCard
          onClick={() => filterProducts("sold")}
          count={status.sold}
          label={"Sold out"}
          icon={(className) => <PiHandbagSimpleBold className={className} />}
          styleStr="sold"
        />
      </div>
      {/* stat wrapper end */}
      {/* table start */}
      <ProductList
        mainData={filteredData}
        selected={selectedProducts}
        onSelectedUpdate={setSelectedProducts}
        innerFormUpdate={editedForm}
        onRefresh={() => getProducts()}
        onInnerFormUpdate={(form) => {
          setEditedForm(form);
        }}
        onRowSelect={(row) => {
          onNavigate && onNavigate(1);
          setCurProduct(row);
        }}
        menus={[
          editedForm.length > 0 ? (
            <Button
              label={`Save Changes ${editedForm.length}`}
              onClick={() => {
                handleProductMassUpdate();
              }}
            />
          ) : (
            <></>
          ),
          editedForm.length > 0 ? (
            <Button
              label="Cancel"
              btnType="outline"
              onClick={() => setEditedForm([])}
            />
          ) : (
            <></>
          ),
        ]}
      />
      {/* table end */}
    </div>
  );
};

const ProductDashboard = () => {
  const { productActiveScreenIndex, setProductActiveScreenIndex } = useMainAppContext();

  const [currProduct, setCurProduct] = useState<any>({});
  const [editProductDataFull, setEditProductDataFull] = useState<any>({});

  useEffect(() => {
    return () => setProductActiveScreenIndex(0);
  }, []);

  const screens: JSX.Element[] = [
    <ProductDashboardFistScreen
      onNavigate={(s) => setProductActiveScreenIndex(s)}
      setCurProduct={(p) => setCurProduct(p)}
    />,
    <ProductDetails
      onNavigate={(s) => setProductActiveScreenIndex(s)}
      curProduct={currProduct}
      setEditProductDataFull={setEditProductDataFull}
      productActiveScreenIndex={productActiveScreenIndex}
    />,
    <ProductEdit
      onNavigate={(s) => setProductActiveScreenIndex(s)}
      editProductDataFull={editProductDataFull}
    />,
    <ProductAdd onNavigate={(s) => setProductActiveScreenIndex(s)} curProduct={currProduct} />,
  ];

  return screens[productActiveScreenIndex];
};

export default ProductDashboard;
