import React, { useEffect, useState } from "react";
import { useMainAppContext } from "../../context/appContext/MainAppContext";
import { MDBSpinner } from "mdb-react-ui-kit";

type Props = {
  imageUrl?: string;
  loader?: JSX.Element;
  imageStyle?: React.CSSProperties;
  alt: string;
  className?: string;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  defaultImg?: string;
};

const RenderImage = ({ imageUrl, imageStyle, loader, alt, className, onMouseOver, onMouseOut, defaultImg}: Props) => {
  const [mainImage, setMainImage] = useState("");
  const { getSignedAwsUrl } = useMainAppContext();

  useEffect(() => {
    (async () => {
      if (imageUrl) setMainImage(await getSignedAwsUrl(imageUrl, "products"));
      else setMainImage(defaultImg || "/logo.png");
    })();
  }, [defaultImg, getSignedAwsUrl, imageUrl]);

  return mainImage ? (
    <img 
    onMouseOver={(e) => {
      e.stopPropagation();
      onMouseOver && onMouseOver()
    }}
    onBlur={() => onMouseOut && onMouseOut()}
    className={className} 
    alt={alt} style={{width: '3rem',...imageStyle}} src={mainImage} />
  ) : loader ? (
    loader
  ) : (
    <MDBSpinner size="sm" className="mx-2" color="primary">
      <span className="visually-hidden">Loading...</span>
    </MDBSpinner>
  );
};

export default RenderImage;
