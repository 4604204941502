import React from "react";
import "../../styles/index.css";
import "./settings.css";
import TabSwitcher from "../../components/tabSwitcher/TabSwitcher";
import { VscAccount } from "react-icons/vsc";
import AccountInfo from "./accountInfo/AccountInfo";
import { CgPassword } from "react-icons/cg";
import { BsCreditCard } from "react-icons/bs";
import { BiUserCheck } from "react-icons/bi";
import SecuritySettings from "./security/SecuritySettings";
import BillingSettings from "./billing/BillingSettings";
import UserPermission from "./userPermission/UserPermission";

const Settings = function ({ display }) {
  if (!display) return;

  return (
    <div className="screen-container">
      <TabSwitcher
        title="Settings"
        panes={[
          {
            tab: (
              <div>
                <VscAccount style={{ fontSize: "1rem" }} />
                <span>Account info</span>
              </div>
            ),
            body: <AccountInfo />,
          },
          {
            tab: (
              <div>
                <CgPassword style={{ fontSize: "1rem" }} />
                <span>Security</span>
              </div>
            ),
            body: <SecuritySettings />,
          },
          {
            tab: (
              <div>
                <BsCreditCard style={{ fontSize: "1rem" }} />
                <span>Wallet</span>
              </div>
            ),
            body: <BillingSettings />,
          },
          // {
          //   tab: (
          //     <div>
          //       <BiUserCheck style={{ fontSize: "1rem" }} />
          //       <span>User and permission</span>
          //     </div>
          //   ),
          //   body: <UserPermission />,
          // },
        ]}
      />
    </div>
  );
};

export default Settings;
