export const UpdateUserInfoUrl = (userID: string): string => `user/update/profile/${userID}`;

export const UpdateUserProfilePicUrl = (userID: string): string => `user/update/profile-pic/${userID}` 

export const UpdateEstateLogoUrl =  `estate/update/logo` 

export const CreateBroadCastUrl = (userID?: string): string => `broadcasts/create` 
export const getBroadCastUrl = (broadcastID?: string): string => broadcastID? `broadcasts/get/${broadcastID}` : `broadcasts/get`

// export const getProduct = (sort?: string): string => `product/find/advanced?sort=price,+quantityAvailable`



