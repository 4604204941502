import { useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useMainAppContext } from "../../../context/appContext/MainAppContext";
import { popup } from "../../../vanilla-functions/model";
import { EditBankPopup } from "../../../components/popup/bankpopup/edit-bank-poup";

type Props = {
  bankCardIcon?: string;
  accountName?: string;
  bankAccountNumber?: string;
  bankName?: string;
  bankLogo?: string;
  isPrimary?: boolean;
  id: string;
  fullData: any;
};

const BankCard = ({ accountName, bankName, bankAccountNumber, isPrimary, id, fullData }: Props) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { userData, updateUsersData, backendServerUrl, setIsLoading } = useMainAppContext();

  async function setAsPrimary() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServerUrl}/vendor/bank/update/${userData?.vendor?._id}/${id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("vmstoken") || "",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            is_primary: true,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      popup("Bank set as primary");
      setIsLoading(false);
      updateUsersData();
    } catch (err) {
      popup("Something went wrong");
      setIsLoading(false);
    }
  }

  async function deleteAccount() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServerUrl}/vendor/bank/delete/${userData?.vendor?._id}/${id}`, {
          method: "DELETE",
          credentials: "include",
          headers: {
            token: localStorage.getItem("vmstoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      popup("Bank account deleted");
      setIsLoading(false);
      updateUsersData();
    } catch (err) {
      popup("Something went wrong");
      setIsLoading(false);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "1.5rem",
        border: "1px solid var(--others-strokes, rgba(58, 53, 65, 0.23))",
        borderRadius: "0.25rem",
        padding: "1rem 0.625rem",
      }}
      title={bankName}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <strong title={bankAccountNumber}>{accountName}</strong>
        <div>
          <strong className="small">{bankName}</strong>
          {isPrimary && (
            <span className="small" style={{ color: "var(--primary-50)", marginLeft: "0.5rem" }}>
              primary{" "}
            </span>
          )}
          {!isPrimary && (
            <span
              className="small"
              onClick={setAsPrimary}
              style={{
                color: "var(--primary-50)",
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            >
              set as primary{" "}
            </span>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <MdOutlineEdit cursor={"pointer"} onClick={() => setShowEditModal(true)} />
        <RiDeleteBin6Line color="#FF4C51" cursor={"pointer"} onClick={deleteAccount} />
      </div>

      <EditBankPopup
        isOpen={showEditModal}
        setIsOpen={() => setShowEditModal(false)}
        data={fullData}
      />
    </div>
  );
};

export default BankCard;
