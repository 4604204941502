import React, { useContext, useState, useEffect } from "react";
import "../../styles/index.css";
import "./wallet-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import OrderItem from "../../screens/orders/children/kids/order-item";
import { AiOutlinePrinter } from "react-icons/ai";

const WalletModal = function ({ display, data, setWalletModal }) {
  const [orderItems, setOrderItems] = useState([]);
  const [theTransaction, setTheTransaction] = useState({});
  const [_theOrder, setTheOrder] = useState({});
  const { backendServer, setIsLoading, usersData } = useContext(AppContext);

  useEffect(() => {
    getData();

    return () => {
      setTheTransaction({});
      setTheOrder({});
      setOrderItems([]);
    };
  }, [data, display]);

  async function getData() {
    if (!data) return;
    try {
      setIsLoading(true);

      const theTransaction = usersData?.vendor?.wallet?.transactions?.find((e) => e?._id === data);
      setTheTransaction(theTransaction);

      if (theTransaction?.orderID) {
        const returned = await (
          await fetch(
            `${backendServer}/order/vendor/get/${usersData?.vendor?._id}/${theTransaction?.orderID}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                token: localStorage.getItem("vmstoken"),
              },
            }
          )
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        setOrderItems(returned?.data?.products?.map((e, i) => <OrderItem key={i} data={e} />));
        setTheOrder(returned?.data);
      }

      setIsLoading(false);
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
      popup("Error fetching vendor orders");
    }
  }

  if (!display) return;

  return (
    <div className="modal-container wallet-edit-modal-container">
      <div className="modal-body edit-wallet-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setWalletModal({
                display: false,
                data: null,
              });
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>
        <div className="wallet-modal-cont flex-column align-column-left">
          <p className="big boldText">Transaction Details</p>
          <div className="each-box-withdraw">
            <p className="small">
              <span className="boldText">Transaction ID</span>: {theTransaction?._id}
            </p>
            <p className="small">
              {" "}
              <span className="boldText">Type</span>: {theTransaction?.transaction_type}
            </p>
            <p className="small">
              <span className="boldText">Status</span>:{" "}
              <span
                style={{
                  color: `${
                    theTransaction?.status === "pending"
                      ? "#FFB400"
                      : theTransaction?.status === "completed"
                      ? "#52AC10"
                      : "#ff8680"
                  }`,
                }}
              >
                {theTransaction?.status === "pending" ? "processing" : theTransaction?.status}
              </span>
            </p>
          </div>

          <div className="each-box-withdraw">
            <p className="small">
              {" "}
              <span className="boldText">From</span>: Estility
            </p>
            <p className="small">
              {" "}
              <span className="boldText">To</span>: {usersData?.vendor?.vendor_name}
            </p>
            <p className="small">
              {" "}
              <span className="boldText">Account Number</span>:{" "}
              {usersData?.vendor?.bank_accounts.find((e) => e?.is_primary).account_number}
            </p>
            <p className="small">
              {" "}
              <span className="boldText">Bank</span>:{" "}
              {usersData?.vendor?.bank_accounts.find((e) => e?.is_primary).bank_name}
            </p>
          </div>

          {orderItems?.length > 0 && <div className="w-order-d-items">{orderItems}</div>}

          <div className="each-box-withdraw">
            <div className="flex-row" style={{ justifyContent: "space-between" }}>
              <p className="small boldText">Total</p>
              <p className="small">{theTransaction?.amount || "-"}</p>
            </div>
          </div>

          <div className="flex-row">
            <button
              onClick={() => window.print()}
              className="wallet-modal-btn"
              style={{ color: "#7975B6" }}
            >
              <AiOutlinePrinter size={20} style={{ marginTop: -3 }} color="#7975B6" />
              &nbsp; PRINT RECIEPT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletModal;
