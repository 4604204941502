import React, { useState, useEffect, useContext } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { sortData, columns } from "./data";
import "./orders-table.css";
import AppContext from "../../context/app-context";
let pseudoCheckBoxSelectedData = [];

function OrdersTable({
  data,
  limit,
  showPageNumber,
  page,
  setPage,
  setCheckBoxSelectedData,
  setcsvData,
  setScreen,
  dashboard,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(limit);

  const { changeScreen } = useContext(AppContext);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function makeChecboxComeAlive(event) {
    if (pseudoCheckBoxSelectedData.find((e) => e._id === event?.target?.getAttribute("data"))) {
      pseudoCheckBoxSelectedData = pseudoCheckBoxSelectedData.filter(
        (e) => e._id !== event?.target?.getAttribute("data")
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      setcsvData([
        ["Order No.", "Rider Name", "Item Name", "Item Count", "Amount", "Date and Time", "Status"],
        ...pseudoCheckBoxSelectedData?.map(
          ({ products, status, _id, rider, createdAt, totalAmount }) => {
            return [
              _id,
              rider?.first_name || "-",
              products[0]?.productID?.product_name?.slice(0, 20) + "...",
              products?.length,
              new Intl.NumberFormat("en-us").format(totalAmount),
              new Intl.DateTimeFormat("en-gb", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(createdAt)),
              status === "pending"
                ? "pending"
                : status === "processing"
                ? "packed"
                : status === "shipped"
                ? "shipped"
                : status === "completed"
                ? "delivered"
                : "returned",
              ,
            ];
          }
        ),
      ]);
    } else {
      pseudoCheckBoxSelectedData.push(
        data?.find((e) => e?._id === event?.target?.getAttribute("data"))
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      setcsvData([
        ["Order No.", "Rider Name", "Item Name", "Item Count", "Amount", "Date and Time", "Status"],
        ...pseudoCheckBoxSelectedData?.map(
          ({ products, status, _id, rider, createdAt, totalAmount }) => {
            return [
              _id,
              rider?.first_name || "-",
              products[0]?.productID?.product_name?.slice(0, 20) + "...",
              products?.length,
              new Intl.NumberFormat("en-us").format(totalAmount),
              new Intl.DateTimeFormat("en-gb", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(createdAt)),
              status === "pending"
                ? "pending"
                : status === "processing"
                ? "packed"
                : status === "shipped"
                ? "shipped"
                : status === "completed"
                ? "delivered"
                : "returned",
            ];
          }
        ),
      ]);
    }
  }

  useEffect(() => {
    document.querySelectorAll("#o-checkbox-children").forEach((element) => {
      if (pseudoCheckBoxSelectedData.find((e) => e._id === element?.getAttribute("data"))) {
        element.checked = true;
      } else element.checked = false;
    });
  });

  function allActionCTable(add) {
    if (add) {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#o-checkbox-children").forEach((element) => {
        pseudoCheckBoxSelectedData.push(
          data?.find((e) => e?._id === element?.getAttribute("data"))
        );
        element.checked = true;
      });

      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);

      setcsvData([
        ["Order No.", "Rider Name", "Item Name", "Item Count", "Amount", "Date and Time", "Status"],
        ...pseudoCheckBoxSelectedData?.map(
          ({ products, status, _id, rider, createdAt, totalAmount }) => {
            return [
              _id,
              rider?.first_name || "-",
              products[0]?.productID?.product_name?.slice(0, 20) + "...",
              products?.length,
              new Intl.NumberFormat("en-us").format(totalAmount),
              new Intl.DateTimeFormat("en-gb", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(createdAt)),
              status === "pending"
                ? "pending"
                : status === "processing"
                ? "packed"
                : status === "shipped"
                ? "shipped"
                : status === "completed"
                ? "delivered"
                : "returned",
              ,
            ];
          }
        ),
      ]);
    } else {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#o-checkbox-children").forEach((element) => {
        element.checked = false;
      });
      setcsvData([
        ["Order No.", "Rider Name", "Item Name", "Item Count", "Amount", "Date and Time", "Status"],
        ...pseudoCheckBoxSelectedData?.map(
          ({ products, status, _id, rider, createdAt, totalAmount }) => {
            return [
              _id,
              rider?.first_name || "-",
              products[0]?.productID?.product_name?.slice(0, 20) + "...",
              products?.length,
              new Intl.NumberFormat("en-us").format(totalAmount),
              new Intl.DateTimeFormat("en-gb", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(createdAt)),
              status === "pending"
                ? "pending"
                : status === "processing"
                ? "packed"
                : status === "shipped"
                ? "shipped"
                : status === "completed"
                ? "delivered"
                : "returned",
              ,
            ];
          }
        ),
      ]);
    }
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "fit-content" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  onClick={(e) => {
                    if (e.target.className.includes("o-table-checkboxes-head")) {
                      if (!e.target.checked) allActionCTable(false);
                      else allActionCTable(true);
                    }
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortData(data)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          onClick={(event) => {
                            if (!setScreen) return;
                            if (event.target.className.includes("o-table-checkboxes")) {
                              event.stopPropagation();
                              return makeChecboxComeAlive(event);
                            }
                            if (dashboard) changeScreen("ORDERS");
                            setScreen({
                              screen: "details",
                              data: event?.target
                                ?.closest(".MuiTableRow-root")
                                .children[1].textContent.trim(),
                            });
                          }}
                          key={column.id}
                          align={column.align}
                          style={{ cursor: "pointer" }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {showPageNumber && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortData(data).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}

export { OrdersTable, pseudoCheckBoxSelectedData };
