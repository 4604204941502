import React from "react";
import { MdOutlinePrint } from "react-icons/md";
import * as XLSX from "xlsx";

type Props = {
  dataToPrint: any[];
  title?: string;
  className?: string;
  onClick?: () => void;
};

const TablePrinter = (props: Props) => {
  const {
    dataToPrint,
    title = `Table ${new Date()}`,
    onClick,
    className,
  } = props;

  const exportToExcel = (data: any[], filename: string): void => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, filename + ".xlsx");
  };

  // Example usage

  const handlePrint = () => {
    dataToPrint.length > 0 && exportToExcel(dataToPrint, title);
  };
  return (
    <div
      className={className}
      title="Print CSV"
      onClick={() => {
        onClick && onClick();
        handlePrint();
      }}
    >
      <MdOutlinePrint size={24} />
    </div>
  );
};

export default TablePrinter;
