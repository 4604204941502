import { ArrowLeft } from "phosphor-react";
import { useState } from "react";
import "./ProductEdit.css";
import { CiImageOn } from "react-icons/ci";
import { FiUploadCloud } from "react-icons/fi";
import SkillInput from "../productAdd/SkillsInput";
import { useMainAppContext } from "../../../context/appContext/MainAppContext";
import { popup } from "../../../vanilla-functions/model";
import InputField from "../../../components/inputField/InputField";
import RenderImage from "../../../components/renderImage/RenderImage";
import Button from "../../../components/button/Button";

type Props = {
  onNavigate: (s: number) => void;
  editProductDataFull: any;
};

const ProductEdit = ({ onNavigate, editProductDataFull }: Props) => {
  const [state, setState] = useState<any>({...editProductDataFull});
  const [editedState, setEditedState] = useState<any>({_id: editProductDataFull?._id});
  const { emsToken, backendServerUrl, setIsLoading } = useMainAppContext();


  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      const url = `${backendServerUrl}/product/update/${editProductDataFull?._id}`;
      const res = await (
        await fetch(url, {
          headers: {
            token: emsToken,
            "content-type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify(editedState),
        })
      ).json();
      if (res?.status === "Internal server error") throw new Error(res.message);
      popup("Product Details Updated");
      setIsLoading(false);
      onNavigate && onNavigate(1);
    } catch {
      popup("Error Update Product Details");
      setIsLoading(false);
    }
  };

  return (
    <div className="product-dashboard">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => onNavigate && onNavigate(1)}
      >
        <ArrowLeft
          size={"1.5rem"}
          onClick={() => onNavigate && onNavigate(1)}
        />{" "}
        Product Details Edit
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <div
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            gap: "1.3rem",
          }}
        >
          <InputField
            label="Product name"
            name="product_name"
            placeholder={editProductDataFull?.product_name}
            value={state?.product_name}
            labelType="nested"
            onChange={({ target: { name, value } }) => {
              setState((p: any) => ({ ...p, [name]: value }));
              setEditedState((p: any) => ({ ...p, [name]: value }));
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1.5rem",
              alignItems: "center",
            }}
          >
            <InputField
              label="Quantity"
              labelType="nested"
              name="quantityAvailable"
              placeholder={editProductDataFull?.quantityAvailable}
              value={state?.quantityAvailable}
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
                setEditedState((p: any) => ({ ...p, [name]: value }));
              }}
            />
            <InputField
              label="Price(₦)"
              name="minimumPrice"
              placeholder={editProductDataFull?.minimumPrice}
              value={state?.minimumPrice}
              labelType="nested"
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
                setEditedState((p: any) => ({ ...p, [name]: value }));
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1.5rem",
              alignItems: "center",
            }}
          >
            <InputField
              label="Brand Name"
              name="brand_name"
              placeholder={editProductDataFull?.brand_name}
              value={state?.brand_name}
              labelType="nested"
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
                setEditedState((p: any) => ({ ...p, [name]: value }));
              }}
            />
          </div>

          <InputField
            label="Description"
            labelType="nested"
            placeholder={editProductDataFull?.description}
            name="description"
            value={state?.description}
            type="textarea"
            onChange={({ target: { name, value } }) => {
              setState((p: any) => ({ ...p, [name]: value }));
              setEditedState((p: any) => ({ ...p, [name]: value }));
            }}
          />

        <SkillInput
            label="Product categories"
            name="sub_categories"
            placeholder="Enter product categories"
            onSkillsChange={(tags: string[]) => {
              const tag = tags.join(",");
              setState((p: any) => ({ ...p, sub_categories: tag }));
              setEditedState((p: any) => ({ ...p, sub_categories: tag }));
            }}
            value={state["sub_categories"]}
          />
          <p className="small redText removemargin" style={{ marginTop: -10 }}>
            Please use the "enter" key on your keyboard to add a category
          </p>

          <SkillInput
            label="Product tags"
            name="tags"
            placeholder="Enter product tags"
            onSkillsChange={(skills: string[]) => {
              const tags = skills.join(",");
              setState((p: any) => ({ ...p, tags: tags }));
              setEditedState((p: any) => ({ ...p, tags: tags }));
            }}
            value={state["tags"]}
          />
          <p className="small redText removemargin" style={{ marginTop: -10 }}>
            Please use the "enter" key on your keyboard to add a tag
          </p>
        </div>
        {/* image area start */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "0.5rem",
              border: "2px dotted rgba(58, 53, 65, 0.23)",
              borderRadius: "8px",
              justifyContent: "center",
              width: "22rem",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "0.5rem",
                borderRadius: "8px",
                justifyContent: "center",
                alignItems: "center",
                width: "18rem",
                height: "18rem",
              }}
            >
              <RenderImage
                imageStyle={{ width: "18rem", maxHeight: "18rem" }}
                alt="product_image"
                imageUrl={editProductDataFull?.images[0]}
              />
            </div>
            <div className="product-edit-image">
              <input placeholder="" type="file" />
              <div className="product-edit-image-placeholder">
                <CiImageOn style={{ fontSize: "5rem" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: "#7975B6",
                  }}
                >
                  <FiUploadCloud size={"2rem"} />
                  Upload image
                </div>
                <div style={{ fontSize: "0.75rem" }}>
                  Upload a cover image for your product.
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}
          >
            <div style={{ fontSize: "0.7rem", fontWeight: "500" }}>
              Additional Images
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "22rem",
                overflowY: "auto",
                gap: "1rem",
                scrollbarWidth: "none",
              }}
            >
              {editProductDataFull?.images &&
                editProductDataFull?.images.map((x: any, i: number) => (
                  <div
                    style={{
                      display: "flex",
                      padding: "0.5rem",
                      border: "2px dotted rgba(58, 53, 65, 0.23)",
                      borderRadius: "8px",
                      justifyContent: "center",
                      // width: "45%",
                      alignItems: "center",
                      position: "relative",
                    }}
                    key={i}
                  >
                    <RenderImage
                      imageUrl={x}
                      imageStyle={{ width: "10rem", maxHeight: "10rem" }}
                      alt=""
                    />
                    <div className="product-edit-image">
                      <input
                        onChange={async (e: any) => {
                          try {
                            setIsLoading(true);
                            const form = new FormData();
                            form.append("image", e.target.files[0]);
                            const url = `${backendServerUrl}/product/image/update/${
                              editProductDataFull?._id
                            }/${e.target.getAttribute("data-imagename")}`;
                            const res = await (
                              await fetch(url, {
                                headers: {
                                  token: emsToken,
                                  enctype: "multipart/form-data",
                                },
                                method: "PATCH",
                                body: form,
                              })
                            ).json();
                            if (res?.status === "Internal server error")
                              throw new Error(res.message);
                            popup("Product Image Updated");
                            setIsLoading(false);
                            onNavigate && onNavigate(1);
                          } catch {
                            popup("Error Update Product Image");
                            setIsLoading(false);
                          }
                        }}
                        data-imagename={x}
                        placeholder=""
                        type="file"
                      />
                      <div className="product-edit-image-placeholder">
                        <CiImageOn style={{ fontSize: "2rem" }} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "1px",
                            fontSize: "0.7rem",
                            fontWeight: "bold",
                            color: "#7975B6",
                          }}
                        >
                          <FiUploadCloud size={"1.5rem"} />
                          Upload image
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              textAlign: "center",
            }}
          >
            <Button label="Save Changes" onClick={handleSaveChanges} />
          </div>
        </div>
        {/* image area end */}
      </div>
    </div>
  );
};

export default ProductEdit;
