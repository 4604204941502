import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./wallet.css";
import AppContext from "../../context/app-context";
import { AiOutlineDown } from "react-icons/ai";
import SearchInput from "../../components/input/SearchInput";
import { popup } from "../../vanilla-functions/model";
import { CgSortAz } from "react-icons/cg";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import {
  WalletsTable,
  pseudoCheckBoxSelectedData,
} from "../../components/wallet-table/wallet-table";
import WalletModal from "../../components/wallet-modal/wallet-modal";

import PaidWalletFilter from "../../components/filter-modals/wallet/paid";
import RefundsWalletFilter from "../../components/filter-modals/wallet/refunds";
import PendingWalletFilter from "../../components/filter-modals/wallet/pending";

import w1 from "../../assets/wallet/w1.svg";
import w2 from "../../assets/wallet/w2.svg";
import w3 from "../../assets/wallet/w3.svg";

const filterResultsRaw = {
  walletFilterResults: {
    paid: "",
    pending: "",
    refunds: "",
  },
  totals: {
    paid: "",
    pending: "",
    refunds: "",
  },
};

const statFiltersRaw = {
  paid: {
    from: "",
    to: "",
  },
  pending: {
    from: "",
    to: "",
  },
  refunds: {
    from: "",
    to: "",
  },
};

const Wallet = function ({ display }) {
  const { backendServer, setIsLoading, usersData, patternMatching, shortenNumber } =
    useContext(AppContext);
  const [walletModal, setWalletModal] = useState({
    display: false,
    data: null,
  });
  const [csvData, setcsvData] = useState([]);
  const [page, setPage] = useState(0);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [sortBy, setSortBy] = useState(1);

  const [filterModals, setFilterModals] = useState({
    paid: false,
    pending: false,
    refunds: false,
  });

  const [statFilters, setStatFilters] = useState({});
  const [_filterResults, setFilterResults] = useState({});

  useEffect(() => {
    getData();
  }, [usersData]);

  async function getData() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#w-checkbox-parent"))
      document.querySelector("#w-checkbox-parent").checked = false;
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/vendor/find/${usersData?.vendor?._id}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("vmstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const theReversed = [...returned?.data?.wallet?.transactions];
      theReversed.reverse();

      setWalletData(theReversed);
      setOriginalData(theReversed);

      setIsLoading(false);

      setcsvData([
        ["Date and Time", "Transaction ID", "Transaction Type", "Status", "Amount", "Balance"],
        ...theReversed?.map(({ initiated_on, _id, transaction_type, status, amount, balance }) => {
          return [initiated_on, _id, transaction_type, status, amount, balance];
        }),
      ]);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching vendor wallet data");
    }
  }

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#w-checkbox-parent"))
      document.querySelector("#w-checkbox-parent").checked = false;

    const query = e.target.value;

    if (!query) return getData();

    const foundTransactions = originalData?.filter((e) => {
      return patternMatching(query, e?._id);
    });

    setWalletData(foundTransactions);

    setcsvData([
      ["Date and Time", "Transaction ID", "Transaction Type", "Status", "Amount", "Balance"],
      ...foundTransactions?.map(
        ({ initiated_on, _id, transaction_type, status, amount, balance }) => {
          return [initiated_on, _id, transaction_type, status, amount, balance];
        }
      ),
    ]);
  }

  function sort() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#w-checkbox-parent"))
      document.querySelector("#w-checkbox-parent").checked = false;
    if (sortBy === 1) {
      const sortedArray = [];
      sortedArray.push(...[...originalData].filter((e) => e.status === "completed"));
      sortedArray.push(...[...originalData].filter((e) => e.status === "pending"));
      sortedArray.push(...[...originalData].filter((e) => e.status === "cancelled"));

      setWalletData([...sortedArray]);
      setcsvData([
        ["Date and Time", "Transaction ID", "Transaction Type", "Status", "Amount", "Balance"],
        ...sortedArray?.map(({ initiated_on, _id, transaction_type, status, amount, balance }) => {
          return [initiated_on, _id, transaction_type, status, amount, balance];
        }),
      ]);
    } else if (sortBy === 2) {
      const sortedArray = [];
      sortedArray.push(...[...originalData].filter((e) => e.status === "cancelled"));
      sortedArray.push(...[...originalData].filter((e) => e.status === "pending"));
      sortedArray.push(...[...originalData].filter((e) => e.status === "completed"));
      setWalletData([...sortedArray]);
      setcsvData([
        ["Date and Time", "Transaction ID", "Transaction Type", "Status", "Amount", "Balance"],
        ...sortedArray?.map(({ initiated_on, _id, transaction_type, status, amount, balance }) => {
          return [initiated_on, _id, transaction_type, status, amount, balance];
        }),
      ]);
    } else if (sortBy === 3) {
      const sortedArray = [];
      sortedArray.push(...[...originalData].filter((e) => e.status === "cancelled"));
      sortedArray.push(...[...originalData].filter((e) => e.status === "pending"));
      sortedArray.push(...[...originalData].filter((e) => e.status === "completed"));
      setWalletData([...sortedArray]);
      setcsvData([
        ["Date and Time", "Transaction ID", "Transaction Type", "Status", "Amount", "Balance"],
        ...sortedArray?.map(({ initiated_on, _id, transaction_type, status, amount, balance }) => {
          return [initiated_on, _id, transaction_type, status, amount, balance];
        }),
      ]);
    }
    if (sortBy === 3) setSortBy(1);
    else setSortBy(sortBy + 1);
  }

  function runDateFilter(field, from, to, yesterdayy, todayy) {
    if (field === "paid") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        statFiltersRaw.paid.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(yesterday));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.initiated_on)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.filter((e) => e.status === "completed" && e.transaction_type === "withdrawal")
          ?.forEach((e) => {
            total = total + e?.amount;
          });

        filterResultsRaw.walletFilterResults.paid = String(total);
        filterResultsRaw.totals.paid = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.initiated_on)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === "completed" && e.transaction_type === "withdrawal")?.length
        );

        setFilterResults({});
      } else if (todayy) {
        const today = new Date(Date.now());

        statFiltersRaw.paid.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(today));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.initiated_on)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.filter((e) => e.status === "completed" && e.transaction_type === "withdrawal")
          ?.forEach((e) => {
            total = total + e?.amount;
          });

        filterResultsRaw.walletFilterResults.paid = String(total);
        filterResultsRaw.totals.paid = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.initiated_on)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === "completed" && e.transaction_type === "withdrawal")?.length
        );

        setFilterResults({});
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        originalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.initiated_on))
              )
            )
              return e;
          })
          ?.filter((e) => e.status === "completed" && e.transaction_type === "withdrawal")
          ?.forEach((e) => {
            total = total + e?.amount;
          });

        filterResultsRaw.walletFilterResults.paid = String(total);
        filterResultsRaw.totals.paid = String(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.initiated_on))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === "completed" && e.transaction_type === "withdrawal")?.length
        );
        setFilterResults({});
      } else popup("Incomplete date range");
    } else if (field === "pending") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        statFiltersRaw.pending.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(yesterday));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.initiated_on)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.filter((e) => e.status === "pending" && e.transaction_type === "withdrawal")
          ?.forEach((e) => {
            total = total + e?.amount;
          });

        filterResultsRaw.walletFilterResults.pending = String(total);
        filterResultsRaw.totals.pending = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.initiated_on)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === "pending" && e.transaction_type === "withdrawal")?.length
        );

        setFilterResults({});
      } else if (todayy) {
        const today = new Date(Date.now());

        statFiltersRaw.pending.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(today));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.initiated_on)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.filter((e) => e.status === "pending" && e.transaction_type === "withdrawal")
          ?.forEach((e) => {
            total = total + e?.amount;
          });

        filterResultsRaw.walletFilterResults.pending = String(total);
        filterResultsRaw.totals.pending = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.initiated_on)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === "pending" && e.transaction_type === "withdrawal")?.length
        );

        setFilterResults({});
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        originalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.initiated_on))
              )
            )
              return e;
          })
          ?.filter((e) => e.status === "pending" && e.transaction_type === "withdrawal")
          ?.forEach((e) => {
            total = total + e?.amount;
          });

        filterResultsRaw.walletFilterResults.pending = String(total);
        filterResultsRaw.totals.pending = String(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.initiated_on))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === "pending" && e.transaction_type === "withdrawal")?.length
        );
        setFilterResults({});
      } else popup("Incomplete date range");
    } else if (field === "refunds") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        statFiltersRaw.refunds.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(yesterday));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.initiated_on)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.filter((e) => e.status === "completed" && e.transaction_type === "refund")
          ?.forEach((e) => {
            total = total + e?.amount;
          });

        filterResultsRaw.walletFilterResults.refunds = String(total);
        filterResultsRaw.totals.refunds = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.initiated_on)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === "completed" && e.transaction_type === "refund")?.length
        );

        setFilterResults({});
      } else if (todayy) {
        const today = new Date(Date.now());

        statFiltersRaw.refunds.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(today));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.initiated_on)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.filter((e) => e.status === "completed" && e.transaction_type === "refund")
          ?.forEach((e) => {
            total = total + e?.amount;
          });

        filterResultsRaw.walletFilterResults.refunds = String(total);
        filterResultsRaw.totals.refunds = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.initiated_on)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === "completed" && e.transaction_type === "refund")?.length
        );

        setFilterResults({});
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        originalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.initiated_on))
              )
            )
              return e;
          })
          ?.filter((e) => e.status === "completed" && e.transaction_type === "refund")
          ?.forEach((e) => {
            total = total + e?.amount;
          });

        filterResultsRaw.walletFilterResults.refunds = String(total);
        filterResultsRaw.totals.refunds = String(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.initiated_on))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === "completed" && e.transaction_type === "refund")?.length
        );
        setFilterResults({});
      } else popup("Incomplete date range");
    }

    setFilterModals({
      paid: false,
      pending: false,
      returned: false,
    });
  }

  if (!display) return;

  return (
    <div className="screen-container">
      <div className="dashboard-stat-container flex-row">
        <div className="w-each-d-stat-container">
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={w1} alt="estility-finances" />
              <p className="small blackText removemargin">Paid Out</p>
            </div>

            <div
              className="xsmall removemargin cursor no-hover"
              onClick={() => {
                setFilterModals({
                  paid: true,
                  refunds: false,
                  pending: false,
                });
              }}
            >
              {statFiltersRaw?.paid?.from || statFiltersRaw?.paid?.to ? "Custom" : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <PaidWalletFilter
                display={filterModals?.paid}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin" style={{ paddingLeft: 34 }}>
              ₦
              {shortenNumber(filterResultsRaw?.walletFilterResults?.paid) ||
                (() => {
                  let total = 0;
                  originalData?.forEach((e) => {
                    if (e.transaction_type === "withdrawal" && e?.status === "completed")
                      total = total + e.amount;
                  });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>
          </div>
        </div>

        <div className="w-each-d-stat-container">
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={w2} alt="estility-finances" />
              <p className="small blackText removemargin">Pending Payments</p>
            </div>

            <div
              className="xsmall removemargin cursor no-hover"
              onClick={() => {
                setFilterModals({
                  paid: false,
                  refunds: false,
                  pending: true,
                });
              }}
            >
              {statFiltersRaw?.pending?.from || statFiltersRaw?.pending?.to ? "Custom" : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <PendingWalletFilter
                display={filterModals?.pending}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin" style={{ paddingLeft: 34 }}>
              ₦
              {shortenNumber(filterResultsRaw?.walletFilterResults?.pending) ||
                (() => {
                  let total = 0;
                  originalData?.forEach((e) => {
                    if (e.transaction_type === "withdrawal" && e?.status === "pending")
                      total = total + e.amount;
                  });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>
          </div>
        </div>

        <div className="w-each-d-stat-container">
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={w3} alt="estility-finances" />
              <p className="small blackText removemargin">Refunds</p>
            </div>

            <div
              className="xsmall removemargin cursor no-hover"
              onClick={() => {
                setFilterModals({
                  paid: false,
                  refunds: true,
                  pending: false,
                });
              }}
            >
              {statFiltersRaw?.refunds?.from || statFiltersRaw?.refunds?.to ? "Custom" : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <RefundsWalletFilter
                display={filterModals?.refunds}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin" style={{ paddingLeft: 34 }}>
              ₦
              {shortenNumber(filterResultsRaw?.walletFilterResults?.refunds) ||
                (() => {
                  let total = 0;
                  originalData?.forEach((e) => {
                    if (e.transaction_type === "refund") total = total + e.amount;
                  });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>
          </div>
        </div>
      </div>

      <div className="flex-row space-between no-padding" style={{ margin: "25px 0px 15px 15px" }}>
        <p className="big removemargin">Recent Transactions</p>
      </div>

      <div className="table-container">
        <div
          className="flex-row space-between no-padding"
          style={{ marginBottom: 15, marginTop: -10 }}
        >
          <SearchInput
            style={{ width: "90%" }}
            onChange={searchPlease}
            placeholder="Search for a transaction"
          />
          <div className="flex-row" style={{ width: "fit-content", gap: 10 }}>
            <CgSortAz onClick={sort} className="cursor" title="Sort By Status" size={30} />
            <CSVLink className="cursor small csv-link" filename={"Vendor Orders"} data={csvData}>
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>

            <FiRefreshCcw className="cursor" title="Refresh Data" size={20} onClick={getData} />
          </div>
        </div>

        <WalletsTable
          data={walletData}
          page={page}
          setPage={setPage}
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          checkBoxSelectedData={checkBoxSelectedData}
          setcsvData={setcsvData}
          walletModal={walletModal}
          setWalletModal={setWalletModal}
        />
      </div>

      <WalletModal
        display={walletModal?.display}
        data={walletModal?.data}
        setWalletModal={setWalletModal}
      />
    </div>
  );
};

export { Wallet, filterResultsRaw, statFiltersRaw };
