import React, { useEffect, useState } from 'react';
import ToggleButton from '../../../components/toggleButton/ToggleButton';
import PermissionChild from './PermissionChild';
import { useMainAppContext } from '../../../context/appContext/MainAppContext';
 

type Props = {}


const UserNotificationSettings = (props: Props) => {
    const [state, setState] = useState<any>({});
    const {userData} = useMainAppContext();

    useEffect(() => {
        // get Previous settings for active user
        const settings = localStorage.getItem(`notification-settings-ems-${userData._id}`)
        if(settings !== null) {
            const _settings: any = JSON.parse(settings);
            setState((p:any) => ({...p, ..._settings}))
        }
    }, [userData._id]) 

    const makeCheck = (state: any) => {
        if(state['emailStatusComplaints']+'' === 'true' && state["pushStatusComplaints"]+'' === 'true' && state['inAppStatusComplaints']+'' === 'true'){
            localStorage.setItem(`notification-settings-ems-${userData._id}`, JSON.stringify({...state, complaintStatus: true}))
            setState((p:any) => ({...p, complaintStatus: true}))
        } 
        
        if(state['emailStatusComplaints']+'' === 'false' || state["pushStatusComplaints"]+'' === 'false' || state['inAppStatusComplaints']+'' === 'false'){
            localStorage.setItem(`notification-settings-ems-${userData._id}`, JSON.stringify({...state, complaintStatus: false}))
            setState((p:any) => ({...p, complaintStatus: false}))
        } 
        
        if(state['emailStatusFiance']+'' === 'true' && state["pushStatusFiance"]+'' === 'true' && state['inAppStatusFiance']+'' === 'true'){
            localStorage.setItem(`notification-settings-ems-${userData._id}`, JSON.stringify({...state, financeStatus: true}))
            setState((p:any) => ({...p, financeStatus: true}))
        } 
         if(state['emailStatusFiance']+'' === 'false' || state["pushStatusFiance"]+'' === 'false' || state['inAppStatusFiance']+'' === 'false'){
            localStorage.setItem(`notification-settings-ems-${userData._id}`, JSON.stringify({...state, financeStatus: false}))
            setState((p:any) => ({...p, financeStatus: false}))
        }
    }


    const handleChange = (name: string, value: any) => {
        makeCheck({...state, [name]: value})
        localStorage.setItem(`notification-settings-ems-${userData._id}`, JSON.stringify({...state, [name]: value}))
        setState((p:any) => ({...p, [name]: value}))
    }
    
    const handleChangeComplain = (name: string, value: boolean) => {
        localStorage.setItem(`notification-settings-ems-${userData._id}`, JSON.stringify({...state, [name]: value, emailStatusComplaints: value, pushStatusComplaints: value,  inAppStatusComplaints: value}))
        setState((p:any) => ({...p, [name]: value, emailStatusComplaints: value, pushStatusComplaints: value,  inAppStatusComplaints: value}))
    }
    
    const handleChangeFiance = (name: string, value: boolean) => {
        localStorage.setItem(`notification-settings-ems-${userData._id}`, JSON.stringify({...state, [name]: value, mailStatusFiance: value, pushStatusFiance: value,  inAppStatusFiance: value}))
        setState((p:any) => ({...p, [name]: value, emailStatusFiance: value, pushStatusFiance: value,  inAppStatusFiance: value }))
    }

    
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        <h4>Notifications Details</h4>
        <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{display:'flex', flexDirection: 'column'}}>
                    <strong>Complaints</strong>
                    <span>Get notified when a new complaint has been reported by resident </span>
                </div>
                    <div>
                    <span style={{fontSize: '0.5rem'}}>{`Toggle ${state['complaintStatus']? 'Off': 'On'} `}</span><ToggleButton value={state['complaintStatus']} onChange={({name, value}) => handleChangeComplain(name, value)} name='complaintStatus' />
                    </div>
            </div>
            <div>
                <PermissionChild  label='Email notification' value={state['emailStatusComplaints']} name="emailStatusComplaints" onChange={({name, value}) => handleChange(name, value)} />
                <PermissionChild  label='Push notification' value={state['pushStatusComplaints']} name="pushStatusComplaints" onChange={({name, value}) => handleChange(name, value)} />
                <PermissionChild  label='In-app notification' value={state['inAppStatusComplaints']} name="inAppStatusComplaints" onChange={({name, value}) => handleChange(name, value)} />
            </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{display:'flex', flexDirection: 'column'}}>
                    <strong>Finance</strong>
                    <span>Get notified when a resident pay estate dues/bill </span>
                </div>
                <div>
                <span style={{fontSize: '0.5rem'}}>{`Toggle ${state['financeStatus']? 'Off': 'On'} `}</span><ToggleButton value={state['financeStatus']} onChange={({name, value}) => handleChangeFiance(name, value)} name='financeStatus' />
                </div>
            </div>
            <div>
                <PermissionChild  label='Email notification' value={state['emailStatusFiance']} name="emailStatusFiance" onChange={({name, value}) => handleChange(name, value)} />
                <PermissionChild  label='Push notification' value={state['pushStatusFiance']} name="pushStatusFiance" onChange={({name, value}) => handleChange(name, value)} />
                <PermissionChild  label='In-app notification' value={state['inAppStatusFiance']} name="inAppStatusFiance" onChange={({name, value}) => handleChange(name, value)} />
                
            </div>
        </div>
    </div>
  )
}

export default UserNotificationSettings