type IShortenText = {
    text: string, maxLen: number, replacer?: string | any;
    callback?: (fullText: string) => void
  }

  export const shortenText = (args: IShortenText) => {
    if (args.text.length < args.maxLen) {
      return {text: args.text, callback: null};
    } else {
      return {
        text: args.text.slice(0, args.maxLen)+(args.replacer || '...'),
        callback: args.callback && args.callback(args.text),
      };
    }
  };


  export const removeProperty = <T, K extends keyof T>(arr: T[], propName: K): Omit<T, K>[] => {
    return arr.map(({ [propName]: prop, ...rest }) => rest);
  };

  type DataItem = {
    [key: string]: any;
  };
  
  export type TableHeaderItem = {
    key: string;
    label: string;
    minWidth?: any;
  };
  
 export const transformDataDTO = (data: DataItem[], tableHeader: TableHeaderItem[]): DataItem[] => {
    return data.map((item) => {
      const transformedItem: DataItem = {};
  
      tableHeader.forEach(({ key, label }) => {
        transformedItem[key] = item[key];
      });
  
      return {...transformedItem, ...item};
    });
  };

  export  const isTableHeaderArrayOfTItem = (
    input: string[] | TableHeaderItem[]
  ): input is TableHeaderItem[] => {
    return (
      Array.isArray(input) &&
      input.every((item) => typeof item === "object" && "key" in item && "label" in item)
    );
  };
  export  const isEachTableHeaderArrayOfTItem = (
    input: string | TableHeaderItem
  ): input is TableHeaderItem => {
    return typeof input === "object" && "key" in input && "label" in input;
  };
  
  
  const padZero = (number: number): string => {
    return number.toString().padStart(2, "0");
  };
  
  export const formatDateToTime = (date: Date): string => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
  
    const formattedDate = `${formattedHours}:${padZero(minutes)}${amOrPm}`;
  
    return formattedDate;
  };

  export interface NestedObject {
    [key: string]: string | number | boolean | NestedObject | NestedObject[];
  }
  
  export function searchNested(nestedObj: any, searchValue: string): boolean {
    if (typeof nestedObj === 'string' || typeof nestedObj === 'boolean' || typeof nestedObj === 'number') {
        return nestedObj.toString().toLowerCase().includes(searchValue.toLowerCase());
    }
  
    if (Array.isArray(nestedObj)) {
        for (let i = 0; i < nestedObj.length; i++) {
            if (searchNested(nestedObj[i], searchValue)) {
                return true;
            }
        }
        return false;
    }
  
    if (typeof nestedObj === 'object') {
        for (let key in nestedObj) {
            if (searchNested(nestedObj[key], searchValue)) {
                return true;
            }
        }
        return false;
    }
  
    return false;
  }