import React, { useEffect, useState } from "react";
import { ProductDto } from "../../dto/product.dto";
import "./ProductList.css";
import { HiDotsVertical } from "react-icons/hi";
import { GrExpand } from "react-icons/gr";
import Table from "../../../../components/table/Table";
import RenderImage from "../../../../components/renderImage/RenderImage";
import ToggleButton from "../../../../components/toggleButton/ToggleButton";

type Props = {
  mainData: any[];
  selected: any[];
  onSelectedUpdate?: (arg: any) => void;
  onInnerFormUpdate?: (data: any[]) => void;
  innerFormUpdate?: any[];
  onRowSelect?: (row: any) => void;
  menus?: JSX.Element[];
  onRefresh?: () => void;
  tableTitle?: string;
};

const ProductList = ({
  mainData,
  selected,
  onSelectedUpdate,
  onInnerFormUpdate,
  innerFormUpdate = [],
  onRowSelect,
  menus,
  onRefresh,
  tableTitle
}: Props) => {
  const [checkedItems, setCheckedItems] = useState<any[]>(selected);

  const [editPriceActive, setEditPriceActive] = useState(false);
  const [editLiveActive, setEditLiveActive] = useState(false);
  const [editQuantityAvailableActive, setEditQuantityAvailableActive] =
    useState(false);
  const [itemsUpdateList, setItemsUpdatedList] =
    useState<any[]>(innerFormUpdate);

  const [currImageUrl, setCurrImageUrl] = useState("");
  const [currImageHover, setCurrImageHover] = useState("");

  useEffect(() => {
    setEditLiveActive(false);
    setEditPriceActive(false);
    setEditQuantityAvailableActive(false);
  }, [mainData]);

  useEffect(() => {
    onSelectedUpdate && onSelectedUpdate(checkedItems);
  }, [checkedItems, onSelectedUpdate]);

  useEffect(() => {
    onInnerFormUpdate && onInnerFormUpdate(itemsUpdateList);
  }, [itemsUpdateList, onInnerFormUpdate]);

  useEffect(() => {
    onInnerFormUpdate && onInnerFormUpdate(innerFormUpdate);
    setItemsUpdatedList(innerFormUpdate);
    const actualStatus = innerFormUpdate.length > 0;
    editLiveActive && setEditLiveActive(actualStatus);
    editPriceActive && setEditPriceActive(actualStatus);
    editQuantityAvailableActive && setEditQuantityAvailableActive(actualStatus);
  }, [innerFormUpdate, onInnerFormUpdate]);

  const tableHeader = [
    "image",
    "SKU",
    "Product Name",
    "Qty sold",
    "Available Qty",
    "Price (₦)",
    "Live",
    "Stock level",
  ];

  type IKey = keyof ProductDto;
  const keyToRender: IKey[] = [
    "images",
    "sku",
    "product_name",
    "quantitySold",
    "quantityAvailable",
    "price",
    "live",
    "stockLevel",
  ];

  const getInStockStyle = (_str: string): React.CSSProperties => {
    const str = _str.toLowerCase();
    const color =
      str === "in-stock"
        ? "var(--success-30)"
        : str === "low-stock"
        ? "var(--warning-30)"
        : "var(--error-30)";
    const bgColor =
      str === "in-stock"
        ? "var(--success-10)"
        : str === "low-stock"
        ? "var(--warning-10)"
        : "var(--error-10)";
    return {
      backgroundColor: bgColor,
      color: color,
      borderRadius: "1.5rem",
      display: "flex",
      padding: "0.43975rem 0.87944rem",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      fontSize: "0.65956rem",
    };
  };

  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.keyCode === 27) {
        setCurrImageUrl("");
      }
    };

    if (currImageUrl !== "") {
      document.addEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [currImageUrl]);

  return (
    <>
      <Table
        data={mainData}
        toRenderField={keyToRender}
        header={tableHeader}
        showSearchBar
        searchHeaderMenus={{ right: menus }}
        searchPlaceholder="Search for a product"
        onRefreshIconClick={() => onRefresh && onRefresh()}
        placeholder="No Product Found"
        tableTitle={tableTitle}
        customTdWrappers={[
          {
            element: (text: string[], row?: any) => {
              return (
                <div className="product-img-overlay-wrapper">
                  <RenderImage
                    imageStyle={{
                      width: "2.5rem",
                      height: "auto",
                      maxHeight: "2.5rem",
                      borderRadius: "1px",
                      
                    }}
                    imageUrl={text[0]}
                    alt="img"
                    onMouseOver={() => {
                      setCurrImageHover(row?.id);
                    }}
                    onMouseOut={() => !!currImageHover && setCurrImageHover("")}
                  />
                  {!!(currImageHover === row?.id) && (
                    <div
                      className="product-img-expand"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrImageUrl(text[0]);
                        setCurrImageHover("");
                      }}
                    >
                      <GrExpand fontSize={"1.5rem"} color="#ffffff" />
                    </div>
                  )}
                </div>
              );
            },
            rowKeyName: "images",
          },
          {
            rowKeyName: "live",
            element: (value: string[], row) => {
              return (
                <div>
                  {editLiveActive ? (
                    <ToggleButton
                      value={value ? true : false}
                      name={row?.id || ""}
                      onChange={({ value }) => {
                        const cur = mainData.find((e) => e.id === row?.id);
                        if (!cur) return;
                        const isExist = itemsUpdateList.find(
                          (e) => e.id === cur.id
                        );
                        if (!isExist) {
                          const newList = [
                            ...itemsUpdateList,
                            { ...cur, live: value },
                          ];
                          setItemsUpdatedList(newList);
                        } else {
                          const newList = itemsUpdateList
                            .filter((e) => e.id !== isExist.id)
                            .concat({ ...isExist, live: value });
                          setItemsUpdatedList(newList);
                        }
                      }}
                    />
                  ) : value ? (
                    "Yes"
                  ) : (
                    "No"
                  )}
                </div>
              );
            },
          },
          {
            element: (value: string) => {
              return (
                <div style={getInStockStyle(value) as React.CSSProperties}>
                  {value}
                </div>
              );
            },
            rowKeyName: "stockLevel",
          },
          {
            rowKeyName: "price",
            element: (text, row) => (
              <SpecialInputField
                restrict="number"
                disabled={!editPriceActive}
                className="product-inline-table-edit-input"
                placeholder={text}
                onChange={({ target: { name, value } }) => {
                  // get list mainList
                  // get actual
                  console.log({ name, value, rowID: row });
                  const cur = mainData.find((e) => e.id === row?.id);

                  if (!cur) return;
                  const isExist = itemsUpdateList.find((e) => e.id === cur.id);
                  if (!isExist) {
                    const newList = [
                      ...itemsUpdateList,
                      { ...cur, price: value },
                    ];
                    setItemsUpdatedList(newList);
                  } else {
                    const newList = itemsUpdateList
                      .filter((e) => e.id !== isExist.id)
                      .concat({ ...isExist, price: value });
                    setItemsUpdatedList(newList);
                  }
                }}
              />
            ),
          },
          {
            rowKeyName: "quantityAvailable",
            element: (text, row) => (
              <SpecialInputField
                restrict="number"
                disabled={!editQuantityAvailableActive}
                className="product-inline-table-edit-input"
                placeholder={text}
                onChange={({ target: { value } }) => {
                  // get list mainList
                  // get actual
                  const cur = mainData.find((e) => e.id === row?.id);

                  if (!cur) return;
                  const isExist = itemsUpdateList.find((e) => e.id === cur.id);
                  if (!isExist) {
                    const newList = [
                      ...itemsUpdateList,
                      { ...cur, quantityAvailable: value },
                    ];
                    setItemsUpdatedList(newList);
                  } else {
                    const newList = itemsUpdateList
                      .filter((e) => e.id !== isExist.id)
                      .concat({ ...isExist, quantityAvailable: value });
                    setItemsUpdatedList(newList);
                  }
                }}
              />
            ),
          },
        ]}
        onRowClick={(row) => {
          onRowSelect && onRowSelect(row);
        }}
        onActionOptionClicks={{
          onOthers: [
            {
              label: "Edit Price",
              onClick: (data) => {
                setEditPriceActive(!editPriceActive);
                setEditLiveActive(false);
                setEditQuantityAvailableActive(false);
              },
            },
            {
              label: "Edit Live Status",
              onClick: (data) => {
                setEditLiveActive(!editLiveActive);
                setEditQuantityAvailableActive(false);
                setEditPriceActive(false);
              },
            },
            {
              label: "Add/Remove Quantity",
              onClick: () => {
                setEditLiveActive(false);
                setEditPriceActive(false);
                setEditQuantityAvailableActive(!editQuantityAvailableActive);
              },
            },
          ],
        }}
        actionOptionIcon={<HiDotsVertical size={"1rem"} />}
      />
      {currImageUrl && (
        <div
          className="product-image-preview-overlay"
          onClick={() => {
            setCurrImageUrl("");
          }}
        >
          <RenderImage
            imageStyle={{
              width: "20rem",
            }}
            imageUrl={currImageUrl}
            alt="image"
          />
        </div>
      )}
    </>
  );
};

export default ProductList;

type ISpecialInputField = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  restrict?: "number" | "text";
  disabled: boolean;
  placeholder: string;
  className: string;
  value?: string;
  name?: string;
};
const SpecialInputField = ({
  onChange,
  restrict = "text",
  disabled,
  className,
  placeholder,
  value,
  name,
}: ISpecialInputField) => {
  const [inputValue, setInputValue] = useState(value || "");
  return (
    <input
      onClick={(e) => e.stopPropagation()}
      value={inputValue}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      name={name}
      onChange={(e) => {
        e.stopPropagation();
        const numericValue = e.target.value.replace(/[^0-9]/g, "");
        if (restrict === "number" && isNaN(Number(e.target.value) * 2)) return;
        setInputValue(numericValue);
        onChange && onChange(e);
      }}
    />
  );
};
