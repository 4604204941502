import React, { useState, useEffect } from "react";
import "../../styles/index.css";
import "./carousel-broadcast.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

let index = 0;

const CarouselNotification = function ({ data, style }) {
  const [_pindex, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.length - 1 === index) {
        index = 0;
        return setIndex(0);
      }
      index = index + 1;
      setIndex((index) => index + 1);
    }, 10000);

    return () => clearInterval(interval);
  }, [data]);

  return (
    <div className="carousel-broadcast-container" style={style}>
      <button
        className="c-b-arrow-container"
        onClick={() => {
          if (index === 0) {
            index = data.length - 1;
            return setIndex(data.length - 1);
          }
          index = index - 1;
          setIndex(index - 1);
        }}
      >
        <IoIosArrowBack />
      </button>
      <div className="c-b-the-message-container">
        <p key={index} className="small removemargin">
          {data[index]?.message}
        </p>
      </div>
      <button
        className="c-b-arrow-container"
        onClick={() => {
          if (data.length - 1 === index) {
            index = 0;
            return setIndex(0);
          }
          index = index + 1;
          setIndex(index + 1);
        }}
      >
        <IoIosArrowForward />
      </button>
    </div>
  );
};

export default CarouselNotification;
