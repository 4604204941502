import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "../login.css";
import AppContext from "../../context/app-context";

const BeginReset = function ({
  display,
  setStage,
  setEmail,
  email,
  setCodeSentFromServer,
  setShowResetPassword,
}) {
  const [disableBtn, setDisableBtn] = useState(false);
  const [error, setError] = useState("");

  const {  setIsLoading, validateEmail, getUserFromGlobalBookAndUpdateState } =
    useContext(AppContext);

  useEffect(() => {
    if (email && validateEmail(email)) setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function beginRest() {
    setError("");
    setIsLoading(true);
    try {
      const BaseURL = await getUserFromGlobalBookAndUpdateState(email);

      if (!BaseURL) throw new Error("Failed to retrieve user information. Please try again later.");

      const returned = await (
        await fetch(`${BaseURL}/password/init`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: email }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setCodeSentFromServer(String(returned?.data));
      setStage("code-screen");
      setIsLoading(false);
    } catch (err) {
      if (err.message === "User Does Not Exist") {
        setError(
          "Sorry that email address wasn’t found in our records, please confirm the spelling and try again"
        );
      } else setError(err.message);
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div className="login-container-one">
      <div className="wavy-container">
        <img className="wavy-img" src="/wavy.png" alt="Estility" />
        <div className="wavy-content">
          <div className="flex-row align-row-left" style={{ gap: 20, width: "50%" }}>
            <img className="wavy-logo" src="/logo.png" alt="Estility" />
            <p className="big whiteText removemargin" style={{ fontSize: "25px" }}>
              Estility
            </p>
          </div>
          {/* <p className="small blackText cursor">
                    <FiSettings style={{ marginTop: -5 }} /> &nbsp; Help
                  </p> */}
        </div>
      </div>

      <div className="login-cont">
        <div className="flex-row align-row-left" style={{ width: "55%", gap: 5 }}>
          <p className="small boldText removemargin">
            Please type in your email address to reset your password
          </p>
          <p className="small removemargin redText">{error}</p>
        </div>
        <input
          className="in login-in"
          type="text"
          placeholder="Email Address"
          onChange={(e) => setEmail(e.target.value?.toLowerCase())}
        />

        <button disabled={disableBtn} onClick={beginRest} className="themeBtn login-btn">
          Reset Password
        </button>

        <div className="flex-row" style={{ justifyContent: "flex-end", width: "55%" }}>
          <p
            onClick={() => setShowResetPassword(false)}
            className="small removemargin cursor"
            style={{ color: "#5334D7" }}
          >
            Login Instead
          </p>
        </div>
      </div>
    </div>
  );
};

export default BeginReset;
