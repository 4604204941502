import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./products.css";
import AppContext from "../../context/app-context";
import ProductDashboard from './productDashboard/ProductDashboard'
import { useMainAppContext } from "../../context/appContext/MainAppContext";

const Products = function ({ display}) {
  const { backendServer, setIsLoading, usersData } = useContext(AppContext);

  if (!display) return;

  return (
    <div className="screen-container">
      <ProductDashboard />
    </div>
  );
};

export default Products;
