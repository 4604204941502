import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../orders.css";
import AppContext from "../../../context/app-context";
import { AiOutlineDown } from "react-icons/ai";
import { popup } from "../../../vanilla-functions/model";
import { CgSortAz } from "react-icons/cg";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import SearchInput from "../../../components/input/SearchInput";
import {
  OrdersTable,
  pseudoCheckBoxSelectedData,
} from "../../../components/orders-table/orders-table";
import AllOrdersFilter from "../../../components/filter-modals/orders/all";
import NewsOrdersFilter from "../../../components/filter-modals/orders/new";
import DeliveredOrdersFilter from "../../../components/filter-modals/orders/delivered";
import ReturnedOrdersFilter from "../../../components/filter-modals/orders/returned";
import ShippedOrdersFilter from "../../../components/filter-modals/orders/shipped";
import PackedOrderFIlter from "../../../components/filter-modals/orders/packed";
import StatusFilter from "../../../components/popup/filters/status-filter";
import CarouselNotification from "../../../components/carousel-broadcast/carousel-broadcast";
import o1 from "../../../assets/orders/0-1.svg";
import o2 from "../../../assets/orders/0-2.svg";
import o3 from "../../../assets/orders/0-3.svg";
import o4 from "../../../assets/orders/0-4.svg";
import o5 from "../../../assets/orders/0-5.svg";
import o6 from "../../../assets/orders/0-6.svg";

const filterResultsRaw = {
  ordersFilterResults: {
    delivered: "",
    shipped: "",
    returned: "",
    all: "",
    new: "",
    packed: "",
  },
  totals: {
    delivered: "",
    shipped: "",
    returned: "",
    all: "",
    new: "",
    packed: "",
  },
};

const statFiltersRaw = {
  delivered: {
    from: "",
    to: "",
  },
  shipped: {
    from: "",
    to: "",
  },
  returned: {
    from: "",
    to: "",
  },
  all: {
    from: "",
    to: "",
  },
  new: {
    from: "",
    to: "",
  },
  packed: {
    from: "",
    to: "",
  },
};

const OrdersHome = function ({ display, setScreen }) {
  const [csvData, setcsvData] = useState([]);
  const [page, setPage] = useState(0);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [tableFilterStatus, setTableFIlterStatus] = useState("");
  const [tableDateFilter, setTableDateFilter] = useState({
    from: "",
    to: "",
  });
  const { backendServer, setIsLoading, usersData, patternMatching, shortenNumber } =
    useContext(AppContext);

  const [filterModals, setFilterModals] = useState({
    delivered: false,
    shipped: false,
    returned: false,
    all: false,
    new: false,
    packed: false,
  });
  const [statFilters, setStatFilters] = useState({});
  const [_filterResults, setFilterResults] = useState({});

  useEffect(() => {
    getData();
  }, [usersData]);

  async function getData() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#o-checkbox-parent"))
      document.querySelector("#o-checkbox-parent").checked = false;
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/order/vendor/get/${usersData?.vendor?._id}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("vmstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const theReversed = [...returned?.data];
      theReversed.reverse();

      setOrderData(theReversed);
      setOriginalData(theReversed);

      setIsLoading(false);

      setcsvData([
        ["Order No.", "Rider Name", "Item Name", "Item Count", "Amount", "Date and Time", "Status"],
        ...theReversed?.map(({ products, status, _id, rider, createdAt, totalAmount }) => {
          return [
            _id,
            rider?.first_name || "-",
            products[0]?.productID?.product_name?.slice(0, 20) + "...",
            products?.length,
            new Intl.NumberFormat("en-us").format(totalAmount),
            new Intl.DateTimeFormat("en-gb", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(createdAt)),
            status === "pending"
              ? "pending"
              : status === "processing"
              ? "packed"
              : status === "shipped"
              ? "shipped"
              : status === "completed"
              ? "delivered"
              : "returned",
          ];
        }),
      ]);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching vendor orders");
    }
  }

  function workCSV(array) {
    setcsvData([
      ["Order No.", "Rider Name", "Item Name", "Item Count", "Amount", "Date and Time", "Status"],
      ...array.map(({ products, status, _id, rider, createdAt, totalAmount }) => {
        return [
          _id,
          rider?.first_name || "-",
          products[0]?.productID?.product_name?.slice(0, 20) + "...",
          products?.length,
          new Intl.NumberFormat("en-us").format(totalAmount),
          new Intl.DateTimeFormat("en-gb", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(createdAt)),
          status === "pending"
            ? "pending"
            : status === "processing"
            ? "packed"
            : status === "shipped"
            ? "shipped"
            : status === "completed"
            ? "delivered"
            : "returned",
        ];
      }),
    ]);
  }

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#o-checkbox-parent"))
      document.querySelector("#o-checkbox-parent").checked = false;

    const query = e.target.value;

    if (!query) return getData();

    const foundOrders = originalData?.filter((e) => {
      return (
        patternMatching(query, e?._id) ||
        patternMatching(query, e?.rider?.first_name) ||
        patternMatching(query, e?.rider?.last_name)
      );
    });

    setOrderData(foundOrders);

    setcsvData([
      ["Order No.", "Rider Name", "Item Name", "Item Count", "Amount", "Date and Time", "Status"],
      ...foundOrders.map(({ products, status, _id, rider, createdAt, totalAmount }) => {
        return [
          _id,
          rider?.first_name || "-",
          products[0]?.productID?.product_name?.slice(0, 20) + "...",
          products?.length,
          new Intl.NumberFormat("en-us").format(totalAmount),
          new Intl.DateTimeFormat("en-gb", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(createdAt)),
          status === "pending"
            ? "pending"
            : status === "processing"
            ? "packed"
            : status === "shipped"
            ? "shipped"
            : status === "completed"
            ? "delivered"
            : "returned",
        ];
      }),
    ]);
  }

  function runDateFilter(field, from, to, yesterdayy, todayy) {
    if (field === "delivered") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        statFiltersRaw.delivered.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(yesterday));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.filter((e) => e.status === "completed")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.delivered = String(total);
        filterResultsRaw.totals.delivered = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === "completed")?.length
        );

        setFilterResults({});
      } else if (todayy) {
        const today = new Date(Date.now());

        statFiltersRaw.delivered.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(today));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.filter((e) => e.status === "completed")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.delivered = String(total);
        filterResultsRaw.totals.delivered = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === "completed")?.length
        );

        setFilterResults({});
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        originalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })
          ?.filter((e) => e.status === "completed")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.delivered = String(total);
        filterResultsRaw.totals.delivered = String(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === "completed")?.length
        );
        setFilterResults({});
      } else popup("Incomplete date range");
    } else if (field === "shipped") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        statFiltersRaw.shipped.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(yesterday));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.filter((e) => e.status === "shipped")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.shipped = String(total);
        filterResultsRaw.totals.shipped = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === "shipped")?.length
        );

        setFilterResults({});
      } else if (todayy) {
        const today = new Date(Date.now());

        statFiltersRaw.shipped.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(today));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.filter((e) => e.status === "shipped")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.shipped = String(total);
        filterResultsRaw.totals.shipped = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === "shipped")?.length
        );

        setFilterResults({});
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        originalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })
          ?.filter((e) => e.status === "shipped")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.shipped = String(total);
        filterResultsRaw.totals.shipped = String(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === "shipped")?.length
        );
        setFilterResults({});
      } else popup("Incomplete date range");
    } else if (field === "returned") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        statFiltersRaw.returned.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(yesterday));

        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.filter((e) => e.status === "CANCELLED")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.returned = String(total);
        filterResultsRaw.totals.returned = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === "CANCELLED")?.length
        );

        setFilterResults({});
      } else if (todayy) {
        const today = new Date(Date.now());

        statFiltersRaw.returned.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(today));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.filter((e) => e.status === "CANCELLED")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.returned = String(total);
        filterResultsRaw.totals.returned = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === "CANCELLED")?.length
        );

        setFilterResults({});
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        originalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })
          ?.filter((e) => e.status === "CANCELLED")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.returned = String(total);
        filterResultsRaw.totals.returned = String(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === "CANCELLED")?.length
        );
        setFilterResults({});
      } else popup("Incomplete date range");
    } else if (field === "all") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        statFiltersRaw.all.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(yesterday));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.all = String(total);
        filterResultsRaw.totals.all = String(
          originalData.filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })?.length
        );

        setFilterResults({});
      } else if (todayy) {
        const today = new Date(Date.now());

        statFiltersRaw.all.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(today));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.all = String(total);
        filterResultsRaw.totals.all = String(
          originalData.filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })?.length
        );

        setFilterResults({});
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        originalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.all = String(total);
        filterResultsRaw.totals.all = String(
          originalData.filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })?.length
        );
        setFilterResults({});
      } else popup("Incomplete date range");
    } else if (field === "new") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        statFiltersRaw.new.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(yesterday));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.filter((e) => e.status === "pending")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.new = String(total);
        filterResultsRaw.totals.new = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === "pending")?.length
        );

        setFilterResults({});
      } else if (todayy) {
        const today = new Date(Date.now());

        statFiltersRaw.new.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(today));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.filter((e) => e.status === "pending")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.new = String(total);
        filterResultsRaw.totals.new = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === "pending")?.length
        );

        setFilterResults({});
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        originalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })
          ?.filter((e) => e.status === "pending")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.new = String(total);
        filterResultsRaw.totals.new = String(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === "pending")?.length
        );
        setFilterResults({});
      } else popup("Incomplete date range");
    } else if (field === "packed") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        statFiltersRaw.packed.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(yesterday));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.filter((e) => e.status === "processing")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.packed = String(total);
        filterResultsRaw.totals.packed = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === "processing")?.length
        );

        setFilterResults({});
      } else if (todayy) {
        const today = new Date(Date.now());

        statFiltersRaw.packed.from = new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(today));
        setStatFilters({});

        let total = 0;

        originalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.filter((e) => e.status === "processing")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.packed = String(total);
        filterResultsRaw.totals.packed = String(
          originalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === "processing")?.length
        );

        setFilterResults({});
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        originalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })
          ?.filter((e) => e.status === "processing")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        filterResultsRaw.ordersFilterResults.packed = String(total);
        filterResultsRaw.totals.packed = String(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === "processing")?.length
        );
        setFilterResults({});
      } else popup("Incomplete date range");
    }

    setFilterModals({
      delivered: false,
      shipped: false,
      returned: false,
      all: false,
      new: false,
    });
  }

  function runTableFilter() {
    if (tableDateFilter.from && tableDateFilter.to) {
      const startDate = new Date(tableDateFilter.from);
      const endDate = new Date(tableDateFilter.to);

      const datesArray = [];

      // loop from start date to end date
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        datesArray.push(new Date(date));
      }

      const dateRanges = datesArray.map((e) =>
        new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(e))
      );

      if (tableFilterStatus) {
        setOrderData(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt))
                )
              )
                return e;
            })
            .filter((e) => e.status === tableFilterStatus)
        );
        workCSV(
          originalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt))
                )
              )
                return e;
            })
            .filter((e) => e.status === tableFilterStatus)
        );
      } else {
        setOrderData(
          originalData.filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })
        );
        workCSV(
          originalData.filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })
        );
      }
    } else if (!tableDateFilter.from && !tableDateFilter.to && tableFilterStatus) {
      setOrderData(originalData.filter((e) => e.status === tableFilterStatus));
      workCSV(originalData.filter((e) => e.status === tableFilterStatus));
    }

    setShowStatusFilter(false);
  }

  if (!display) return;

  return (
    <div>
      <div className="orders-stat-container flex-row">
        <div style={{ width: "18%" }}>
          <div
            className="o-each-d-stat-container o-each-d-stat-container-one"
            style={{ margin: 0, width: "100%" }}
          >
            <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
              <div
                className="flex-row"
                style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
              >
                <img src={o1} alt="estility-finances" />
                <p className="small blackText removemargin">All Orders</p>
                <span className="xsmall removemargin">
                  ({filterResultsRaw?.totals?.all || originalData?.length})
                </span>
              </div>
            </div>

            <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
              <p className="small removemargin boldText blackText" style={{ paddingLeft: 34 }}>
                ₦
                {shortenNumber(filterResultsRaw?.ordersFilterResults?.all) ||
                  (() => {
                    let total = 0;
                    originalData?.forEach((e) => {
                      total = total + e?.totalAmount;
                    });
                    return shortenNumber(total);
                  })() ||
                  "-"}
              </p>

              <div
                onClick={() => {
                  setFilterModals({
                    delivered: false,
                    shipped: false,
                    returned: false,
                    all: true,
                    new: false,
                  });
                }}
                className="xsmall removemargin cursor no-hover"
              >
                {statFiltersRaw?.all?.from || statFiltersRaw?.all?.to ? "Custom" : "All Time"}{" "}
                <span>
                  <AiOutlineDown size={10} />
                </span>
                <AllOrdersFilter
                  display={filterModals?.all}
                  setDisplay={setFilterModals}
                  setStatFilters={setStatFilters}
                  statFilters={statFilters}
                  runDateFilter={runDateFilter}
                  setFilterResults={setFilterResults}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "75%" }}>
          <CarouselNotification
            data={usersData?.vendor?.notifications?.filter(
              (e) => e.notification_type === "broadcasts"
            )}
            style={{ height: "100px", margin: 0 }}
          />
        </div>
      </div>

      <div className="orders-stat-container flex-row">
        <div className="o-each-d-stat-container o-each-d-stat-container-one">
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={o2} alt="estility-finances" />
              <p className="small blackText removemargin">New Orders</p>
              <span className="xsmall removemargin">
                (
                {filterResultsRaw?.totals?.new ||
                  originalData?.filter((e) => e.status === "pending")?.length}
                )
              </span>
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin boldText blackText" style={{ paddingLeft: 34 }}>
              ₦
              {shortenNumber(filterResultsRaw?.ordersFilterResults?.new) ||
                (() => {
                  let total = 0;
                  originalData
                    ?.filter((e) => e.status === "pending")
                    ?.forEach((e) => {
                      total = total + e?.totalAmount;
                    });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>
            <div
              onClick={() => {
                setFilterModals({
                  delivered: false,
                  shipped: false,
                  returned: false,
                  all: false,
                  new: true,
                });
              }}
              className="xsmall removemargin cursor no-hover"
            >
              {statFiltersRaw?.new?.from || statFiltersRaw?.new?.to ? "Custom" : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <NewsOrdersFilter
                display={filterModals?.new}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>
        </div>

        <div className="o-each-d-stat-container o-each-d-stat-container-one">
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={o3} alt="estility-finances" />
              <p className="small blackText removemargin">Shipped Orders</p>
              <span className="xsmall removemargin">
                (
                {filterResultsRaw?.totals?.shipped ||
                  originalData?.filter((e) => e.status === "shipped")?.length}
                )
              </span>
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin boldText blackText" style={{ paddingLeft: 34 }}>
              ₦
              {shortenNumber(filterResultsRaw?.ordersFilterResults?.shipped) ||
                (() => {
                  let total = 0;
                  originalData
                    ?.filter((e) => e.status === "shipped")
                    ?.forEach((e) => {
                      total = total + e?.totalAmount;
                    });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>

            <div
              onClick={() => {
                setFilterModals({
                  delivered: false,
                  shipped: true,
                  returned: false,
                  all: false,
                  new: false,
                });
              }}
              className="xsmall removemargin cursor no-hover"
            >
              {statFiltersRaw?.shipped?.from || statFiltersRaw?.shipped?.to ? "Custom" : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <ShippedOrdersFilter
                display={filterModals?.shipped}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>
        </div>

        <div
          className="o-each-d-stat-container o-each-d-stat-container-one"
          style={{ width: "fit-content" }}
        >
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={o4} alt="estility-finances" />
              <p className="small blackText removemargin">Delivered Orders</p>
              <span className="xsmall removemargin">
                (
                {filterResultsRaw?.totals?.delivered ||
                  originalData?.filter((e) => e.status === "completed")?.length}
                )
              </span>
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin boldText blackText" style={{ paddingLeft: 34 }}>
              ₦
              {shortenNumber(filterResultsRaw?.ordersFilterResults?.delivered) ||
                (() => {
                  let total = 0;
                  originalData
                    ?.filter((e) => e.status === "completed")
                    ?.forEach((e) => {
                      total = total + e?.totalAmount;
                    });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>

            <div
              onClick={() => {
                setFilterModals({
                  delivered: true,
                  shipped: false,
                  returned: false,
                  all: false,
                  new: false,
                });
              }}
              className="xsmall removemargin cursor no-hover"
            >
              {statFiltersRaw?.delivered?.from || statFiltersRaw?.delivered?.to
                ? "Custom"
                : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <DeliveredOrdersFilter
                display={filterModals?.delivered}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>
        </div>

        <div className="o-each-d-stat-container o-each-d-stat-container-one">
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={o5} alt="estility-finances" />
              <p className="small blackText removemargin">Packed Orders</p>
              <span className="xsmall removemargin">
                (
                {filterResultsRaw?.totals?.packed ||
                  originalData?.filter((e) => e.status === "processing")?.length}
                )
              </span>
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin boldText blackText" style={{ paddingLeft: 34 }}>
              ₦
              {shortenNumber(filterResultsRaw?.ordersFilterResults?.packed) ||
                (() => {
                  let total = 0;
                  originalData
                    ?.filter((e) => e.status === "processing")
                    ?.forEach((e) => {
                      total = total + e?.totalAmount;
                    });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>
            <div
              onClick={() => {
                setFilterModals({
                  delivered: false,
                  shipped: false,
                  returned: false,
                  all: false,
                  new: false,
                  packed: true,
                });
              }}
              className="xsmall removemargin cursor no-hover"
            >
              {statFiltersRaw?.packed?.from || statFiltersRaw?.packed?.to ? "Custom" : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <PackedOrderFIlter
                display={filterModals?.packed}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>
        </div>

        <div
          className="o-each-d-stat-container o-each-d-stat-container-one"
          style={{ width: "fit-content" }}
        >
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={o6} alt="estility-finances" />
              <p className="small blackText removemargin">Returned Orders</p>
              <span className="small removemargin">
                {
                  <span className="xsmall removemargin">
                    (
                    {filterResultsRaw?.totals?.returned ||
                      originalData?.filter((e) => e.status === "CANCELLED")?.length}
                    )
                  </span>
                }
              </span>
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin boldText blackText" style={{ paddingLeft: 34 }}>
              ₦
              {shortenNumber(filterResultsRaw?.ordersFilterResults?.returned) ||
                (() => {
                  let total = 0;
                  originalData
                    ?.filter((e) => e.status === "CANCELLED")
                    ?.forEach((e) => {
                      total = total + e?.totalAmount;
                    });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>

            <div
              onClick={() => {
                setFilterModals({
                  delivered: false,
                  shipped: false,
                  returned: true,
                  all: false,
                  new: false,
                });
              }}
              className="xsmall removemargin cursor no-hover"
            >
              {statFiltersRaw?.returned?.from || statFiltersRaw?.returned?.to
                ? "Custom"
                : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <ReturnedOrdersFilter
                display={filterModals?.returned}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="table-container">
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <SearchInput
            onChange={searchPlease}
            placeholder="Search for a recent order (Order No. | Rider)"
          />
        </div>
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <p className="big removemargin">Recent Orders</p>
          <div className="flex-row" style={{ width: "fit-content", gap: 10 }}>
            <CgSortAz
              onClick={() => setShowStatusFilter(true)}
              className="cursor"
              title="Sort By Status"
              size={30}
            />
            <CSVLink className="cursor small csv-link" filename={"Vendor Orders"} data={csvData}>
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>

            <FiRefreshCcw className="cursor" title="Refresh Data" size={20} onClick={getData} />
          </div>
        </div>

        <OrdersTable
          data={orderData}
          limit={10}
          showPageNumber={true}
          showOptions={true}
          page={page}
          setPage={setPage}
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          checkBoxSelectedData={checkBoxSelectedData}
          setcsvData={setcsvData}
          setScreen={setScreen}
        />
      </div>

      <StatusFilter
        isOpen={showStatusFilter}
        setIsOpen={setShowStatusFilter}
        setTableFIlterStatus={setTableFIlterStatus}
        tableFilterStatus={tableFilterStatus}
        setTableDateFilter={setTableDateFilter}
        tableDateFilter={tableDateFilter}
        runTableFilter={runTableFilter}
        getData={getData}
      />
    </div>
  );
};

export { OrdersHome, filterResultsRaw, statFiltersRaw };
