import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { sortData, columns } from "./data";
import "./wallet-table.css";
let pseudoCheckBoxSelectedData = [];

function WalletsTable({
  data,
  page,
  setPage,
  setCheckBoxSelectedData,
  setcsvData,
  setWalletModal,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function makeChecboxComeAlive(event) {
    if (pseudoCheckBoxSelectedData.find((e) => e._id === event?.target?.getAttribute("data"))) {
      pseudoCheckBoxSelectedData = pseudoCheckBoxSelectedData.filter(
        (e) => e._id !== event?.target?.getAttribute("data")
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      setcsvData([
        ["Date and Time", "Transaction ID", "Transaction Type", "Status", "Amount", "Balance"],
        ...pseudoCheckBoxSelectedData?.map(
          ({ initiated_on, _id, transaction_type, status, amount, balance }) => {
            return [initiated_on, _id, transaction_type, status, amount, balance];
          }
        ),
      ]);
    } else {
      pseudoCheckBoxSelectedData.push(
        data?.find((e) => e?._id === event?.target?.getAttribute("data"))
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      setcsvData([
        ["Date and Time", "Transaction ID", "Transaction Type", "Status", "Amount", "Balance"],
        ...pseudoCheckBoxSelectedData?.map(
          ({ initiated_on, _id, transaction_type, status, amount, balance }) => {
            return [initiated_on, _id, transaction_type, status, amount, balance];
          }
        ),
      ]);
    }
  }

  useEffect(() => {
    document.querySelectorAll("#w-checkbox-children").forEach((element) => {
      if (pseudoCheckBoxSelectedData.find((e) => e._id === element?.getAttribute("data"))) {
        element.checked = true;
      } else element.checked = false;
    });
  });

  function allActionCTable(add) {
    if (add) {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#w-checkbox-children").forEach((element) => {
        pseudoCheckBoxSelectedData.push(
          data?.find((e) => e?._id === element?.getAttribute("data"))
        );
        element.checked = true;
      });

      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);

      setcsvData([
        ["Date and Time", "Transaction ID", "Transaction Type", "Status", "Amount", "Balance"],
        ...pseudoCheckBoxSelectedData?.map(
          ({ initiated_on, _id, transaction_type, status, amount, balance }) => {
            return [initiated_on, _id, transaction_type, status, amount, balance];
          }
        ),
      ]);
    } else {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#w-checkbox-children").forEach((element) => {
        element.checked = false;
      });
      setcsvData([
        ["Date and Time", "Transaction ID", "Transaction Type", "Status", "Amount", "Balance"],
        ...data?.map(({ initiated_on, _id, transaction_type, status, amount, balance }) => {
          return [initiated_on, _id, transaction_type, status, amount, balance];
        }),
      ]);
    }
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "fit-content" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  onClick={(e) => {
                    if (e.target.className.includes("w-table-checkboxes-head")) {
                      if (!e.target.checked) allActionCTable(false);
                      else allActionCTable(true);
                    }
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortData(data)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          onClick={(event) => {
                            if (event.target.className.includes("w-table-checkboxes")) {
                              event.stopPropagation();
                              return makeChecboxComeAlive(event);
                            }
                            setWalletModal({
                              display: true,
                              data: event?.target
                                ?.closest(".MuiTableRow-root")
                                .children[2]?.textContent.trim(),
                            });
                          }}
                          key={column.id}
                          align={column.align}
                          style={{ cursor: "pointer" }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={sortData(data).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export { WalletsTable, pseudoCheckBoxSelectedData };
