


export const productFaq = [
  {
    id: 1,
    question: "Can I update quantities for multiple products at once? ",
    answer: `Certainly! To update quantities for multiple products simultaneously, go to the Products section. On the product page, click the three-dot icon for the desired product(s), select "Add/Remove Quantities," input the needed quantities, and click "Save Changes."`,
  },
  {
    id: 2,
    question: `Can I edit the prices of multiple products together?`,
    answer: `Yes, you can easily change the prices of multiple products simultaneously. Navigate to the Products section, click the three-dot icon for the desired product(s), choose "Edit Price," input the new prices, and click "Save Changes."`,
  },
  {
    id: 3,
    question: `Is it possible to deactivate a product as a vendor?`,
    answer: `Absolutely! To deactivate a product on your page as a vendor, visit the Products section, click the three-dot icon for the desired product(s), select "Edit Live Status," and toggle the product on or off.`,
  },
  {
    id: 4,
    question: `What happens when I turn off a product?`,
    answer: `When a product is turned off, it will no longer appear on the resident app.`,
  },
  {
    id: 5,
    question: `How can I monitor low stock levels for my products?`,
    answer: `To keep track of low stock levels for your products, navigate to the Products module, and then select the "Low on Stock" tab. This tab provides a list of all products that are currently running low in stock.`,
  },
];


export const ordersFaq = [
  {
    id: 1,
    question: `How can I view orders from residents?`,
    answer: `Access the Orders module to view a comprehensive display of all orders originating from residents, as well as those assigned to riders for pickup and delivery.`,
  },
  {
    id: 2,
    question: `What does the "Pending Payments" tab indicate?`,
    answer: `The "Pending Payments" tab houses funds paid to your wallet immediately after delivering an order to a resident. Funds remain in the PENDING PAYMENTS tab for 24 hours post-delivery, after which they move to the PAID OUT tab upon settlement in your bank account.`,
  },
  {
    id: 3,
    question: `When do I receive payment for delivered orders?`,
    answer: `Payment for delivered orders is credited to your wallet immediately upon completing the delivery to a resident. The funds stay in the PENDING PAYMENTS tab for 24 hours and then transition to the PAID OUT tab upon successful settlement in your bank account.`,
  },
  {
    id: 4,
    question: `What does "Paid Out" mean?`,
    answer: `"Paid Out" indicates that funds have been disbursed to your linked bank account.`,
  },
  {
    id: 5,
    question: `How does the refund process work?`,
    answer: `Details about the refund process will be provided in the Refund Policy section or you can contact our support team for specific assistance`,
  },
]



export const walletFaq = [
    {
        id: 1,
        question: `Can I specify multiple account numbers for fund withdrawals?`, 
        answer: `Certainly! You have the flexibility to add multiple account numbers for withdrawals. However, only one account can be designated as the primary account for withdrawals. To add an account number, visit the SETTINGS module, click on BILLING, and choose ADD NEW. Tick the "make this primary account for withdrawals" option on the ADD NEW screen to set it as the primary account.`
    },
    {
        id: 2,
        question: `How do I contact customer support?`,
        answer: `For prompt assistance, navigate to the Support module and click on "Contact Us." Share your concerns or queries, and our support team will address them in a timely manner.`
    }
]
