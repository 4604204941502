import React, { useContext, useEffect } from "react";
import "../../../../styles/index.css";
import "../../orders.css";
import AppContext from "../../../../context/app-context";
import { useState } from "react";
import { MDBSpinner } from "mdb-react-ui-kit";

const OrderItem = function ({ data }) {
  const [prodImage, setProdImage] = useState("");
  const { getSignedAwsUrl, formatNumber } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      if (data?.productID?.images[0])
        setProdImage(await getSignedAwsUrl(data?.productID?.images[0], "products"));
      else setProdImage("/logo.png");
    })();
  }, [data]);

  return (
    <div className="flex-row space-between order-item-cont">
      <div
        className="flex-row align-row-left"
        style={{ width: "fit-content", alignItems: "center", height: "fit-content" }}
      >
        {prodImage ? (
          <img className="order-item-img" src={prodImage} alt="Estility Order Items" />
        ) : (
          <MDBSpinner size="sm" className="mx-2" color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        )}

        <p className="small removemargin">
          <span className="removemargin small blackText boldText">
            {data?.productID?.product_name}
          </span>
          <br></br>
          <span className="removemargin small greyText">{data?.quantity}pc</span>
        </p>
      </div>

      <p className="small removemargin" style={{ marginTop: 30 }}>
        <span className="removemargin small">
          {formatNumber(data?.productID?.minimumPrice)} X {data?.quantity}
        </span>{" "}
        &nbsp; = &nbsp;{" "}
        <span className="removemargin small blackText boldText">
          {formatNumber(data?.productID?.minimumPrice * data?.quantity)}
        </span>
      </p>
    </div>
  );
};

export default OrderItem;
