import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/index.css";
import "./engine.css";
import { Dashboard } from "../screens/dashboard/dashboard";
import Products from "../screens/products/products";
import Orders from "../screens/orders/orders";
import { Wallet } from "../screens/wallet/wallet";
import Support from "../screens/support/support";
import Settings from "../screens/settings/settings";
import EngineTopBar from "../components/engine-top-bar/engine-top-bar";
import EngineBottomBar from "../components/engine-bottom-bar/engine-bottom-bar";

const Engine = forwardRef(function ({ sideNav }, ref) {
  const [onView, setOnView] = useState(
    document.location.hash.toUpperCase()?.replace("#", "").trim() || "DASHBOARD"
  );

  useImperativeHandle(ref, () => {
    return {
      changeScreen(screen) {
        setOnView(screen);
        sideNav?.setActiveNav(screen);
        document.location.hash = capitalizeEachWord(screen);
      },
    };
  });

  function capitalizeEachWord(word) {
    const arr = word.split(" ");
    const mapped = arr.map((e) => {
      return e[0].toUpperCase() + e.slice(1).toLowerCase();
    });

    return mapped.join(" ");
  }

  return (
    <div className="engine-container">
      <EngineTopBar />
      <Dashboard sideNav={sideNav} display={onView === "DASHBOARD" && true} />
      <Orders sideNav={sideNav} display={onView === "ORDERS" && true} />
      <Products sideNav={sideNav} display={onView === "PRODUCTS" && true} />
      <Wallet sideNav={sideNav} display={onView === "WALLET" && true} />
      <Support sideNav={sideNav} display={onView === "SUPPORT" && true} />
      <Settings sideNav={sideNav} display={onView === "SETTINGS" && true} />
      <EngineBottomBar />
    </div>
  );
});

export default Engine;
