import React, { useContext, useState, useEffect } from "react";
import "../../styles/index.css";
import "./engine-top-bar.css";
import AppContext from "../../context/app-context";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useMainAppContext } from "../../context/appContext/MainAppContext";
import Notifications from "../notification-popup/notification";

function EngineTopBar({}) {
  const [userImage, setUserImage] = useState("");
  const { usersData, getSignedAwsUrl, changeScreen } = useContext(AppContext);
  const [showNotifications, setShowNotifications] = useState(false);
  const { setProductActiveScreenIndex } = useMainAppContext();

  useEffect(() => {
    (async function () {
      setUserImage(await getSignedAwsUrl(usersData?.profile_picture, "profile-pictures"));
    })();
  }, [usersData]);

  return (
    <div className="top-bar-container">
      <p className="big removemargin">
        Welcome back, {usersData?.first_name} {usersData?.last_name} 👋
        <br></br>{" "}
        <span className="small removemargin">Here's what is happening in your store today.</span>
      </p>

      <div className="flex-row" style={{ width: "fit-content", gap: 15, height: "fit-content" }}>
        <button
          onClick={() => {
            setProductActiveScreenIndex(3);
            changeScreen("PRODUCTS");
          }}
          className="top-bar-btn themeBtn"
        >
          + &nbsp; Add a Product
        </button>
        <IoIosNotificationsOutline
          className="cursor"
          onClick={() => setShowNotifications(true)}
          size={20}
        />
        <img
          src={userImage || "/user_placeholder.png"}
          alt="estility admin"
          className="top-bar-profile-pic"
        />
        <div className="flex-column align-column-left" style={{ width: "fit-content" }}>
          <p className="small removemargin">
            {usersData?.first_name} {usersData?.last_name}
          </p>
          <p className="top-bar-status small removemargin">{usersData?.role}</p>
        </div>
      </div>

      <Notifications isOpen={showNotifications} setIsOpen={setShowNotifications} />
    </div>
  );
}

export default EngineTopBar;
