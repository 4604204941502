import React from "react";
import { CiSearch } from "react-icons/ci";
import "./SearchInput.css";

const SearchInput = ({ onChange, placeholder, style }) => {
  return (
    <div className="search-input-box-wrapper" style={style}>
      <CiSearch />
      <input placeholder={placeholder || ""} onChange={onChange} />
    </div>
  );
};

export default SearchInput;
