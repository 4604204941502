import { ArrowLeft } from "phosphor-react";
import React, { useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import InputField from "../../../components/inputField/InputField";
import SelectInput from "../../../components/selectInput/SelectInput";
import "./ProductAdd.css";
import { CiImageOn } from "react-icons/ci";
import { FiUploadCloud } from "react-icons/fi";
import { useMainAppContext } from "../../../context/appContext/MainAppContext";
import { popup } from "../../../vanilla-functions/model";
import SkillInput from "./SkillsInput";
import RenderImage from "../../../components/renderImage/RenderImage";
import { fileToBase64, getImageUrlFromFile } from "../../../utils";

type Props = {
  onNavigate: (s: number) => void;
  curProduct?: any;
  navLabel?: string;
  type?: "edit" | "add";
};

const ProductAdd = ({ onNavigate, curProduct, navLabel, type = "add" }: Props) => {
  const { backendServerUrl, emsToken, setIsLoading, userData } = useMainAppContext();
  const [state, setState] = useState<any>({});
  const [images, setImages] = useState<File[]>([]);
  const [imagesLocal, setImagesLocal] = useState<string[]>([]);
  const [img1, setImg1] = useState<File>();
  const [img1Url, setImg1Url] = useState<string>("");
  const [img2, setImg2] = useState<File>();
  const [img2Url, setImg2Url] = useState<string>("");
  const [img3, setImg3] = useState<File>();
  const [img3Url, setImg3Url] = useState<string>("");

  useEffect(() => {
    if (type === "edit") {
      curProduct &&
        curProduct?.images &&
        curProduct?.images.forEach((el: any, i: number) => {
          i === 0 && setImg1Url(el);
          i === 1 && setImg2Url(el);
          i === 2 && setImg3Url(el);
        });
    }
  }, [curProduct, curProduct?.images, type]);

  const handleCreateProduct = async () => {
    if (
      !state.product_name ||
      !state.category ||
      !state.minimumPrice ||
      !state.sub_categories ||
      !state.description ||
      !state.tags ||
      !state.quantityAvailable ||
      (!img1 && !img2 && !img3)
    )
      return popup("Please fill in all fields");

    const url = `${backendServerUrl}/product/create`;

    const formData = new FormData();
    formData.append("product_name", state.product_name);
    formData.append("category", state.category);
    formData.append("minimumPrice", state.minimumPrice);
    formData.append("sub_categories", state.sub_categories);
    formData.append("description", state.description);
    formData.append("vendor", userData.vendor._id);
    formData.append("tags", state.tags);
    formData.append("quantityAvailable", state.quantityAvailable);
    formData.append("brand_name", state.brand_name);

    [img1, img2, img3].forEach((image) => image && formData.append("image", image));

    try {
      setIsLoading(true);
      const res = await (
        await fetch(url, {
          headers: {
            token: emsToken,
            enctype: "multipart/form-data",
          },
          method: "POST",
          body: formData,
        })
      ).json();

      const { status, message } = res;

      if (status.toLowerCase().includes("error")) {
        message && popup("Something went wrong, ensure you have filled in all the fields");
        setIsLoading(false);
      }
      popup("Product created successfully!");
      setIsLoading(false);
      setState({});
      onNavigate && onNavigate(0);
    } catch (error: any) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { files } = e.target;

    if (files !== null) {
      const file = files[0];
      const url = await fileToBase64(file);

      const itExist = images.find((x) => x.name === file.name);
      if (itExist) {
        popup(`${file.name} already exist!`);
        return;
      }

      if (index === 0) {
        setImg1(file);
        setImg1Url(url);
      }

      if (index === 1) {
        setImg2(file);
        setImg2Url(url);
      }

      if (index === 2) {
        setImg3(file);
        setImg3Url(url);
      }

      setImages((previousFiles: File[]) => {
        const itExist = previousFiles.find((x) => x.name === file.name);
        itExist && popup(`${file.name} already exist!`);
        if (itExist === undefined) {
          return [...previousFiles, file];
        } else {
          return previousFiles;
        }
      });
    }
  };

  return (
    <div className="product-dashboard">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => onNavigate && onNavigate(0)}
      >
        <ArrowLeft size={"1.5rem"} onClick={() => onNavigate && onNavigate(1)} />{" "}
        {type === "add" ? "New Product" : "Product Details"}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          padding: " 1.5rem",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "60%", display: "flex", flexDirection: "column", gap: "1.3rem" }}>
          <InputField
            label="Product name"
            name="product_name"
            value={state.product_name}
            labelType="nested"
            onChange={({ target: { name, value } }) => {
              setState((p: any) => ({ ...p, [name]: value }));
            }}
          />

          <div
            style={{ display: "flex", flexDirection: "row", gap: "1.5rem", alignItems: "center" }}
          >
            <SelectInput
              label="Category"
              labelType="nested"
              name="category"
              value={state.category}
              options={["Groceries"]}
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
              }}
            />

            <InputField
              label="Price (₦)"
              name="minimumPrice"
              value={state.minimumPrice}
              labelType="nested"
              formatValue="number"
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
              }}
            />
          </div>

          <div
            style={{ display: "flex", flexDirection: "row", gap: "1.5rem", alignItems: "center" }}
          >
            <InputField
              label="Quantity"
              labelType="nested"
              formatValue="number"
              name="quantityAvailable"
              value={state.quantityAvailable}
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
              }}
            />
            <InputField
              label="Brand Name"
              name="brand_name"
              value={state.brand_name}
              labelType="nested"
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
              }}
            />
          </div>

          <div
            style={{ display: "none", flexDirection: "row", gap: "1.5rem", alignItems: "center" }}
          >
            <InputField
              label="Weight(KG)"
              labelType="nested"
              name="weight"
              value={state.weight}
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
              }}
            />

            <InputField
              label="Size(Length*weight*height)"
              labelType="nested"
              placeholder="80x80x80"
              name="size"
              value={state.size}
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
              }}
            />
          </div>

          <InputField
            label="Description"
            labelType="nested"
            placeholder="description"
            name="description"
            value={state.description}
            type="textarea"
            onChange={({ target: { name, value } }) => {
              setState((p: any) => ({ ...p, [name]: value }));
            }}
            maxLength={300}
          />

          <SkillInput
            label="Product categories"
            name="sub_categories"
            placeholder="Enter product categories"
            onSkillsChange={(tags: string[]) => {
              const tag = tags.join(",");
              setState((p: any) => ({ ...p, sub_categories: tag }));
            }}
            value={state["sub_categories"]}
          />
          <p className="small redText removemargin" style={{ marginTop: -10 }}>
            Please use the "enter" key on your keyboard to add a category
          </p>
          <SkillInput
            label="Product tags"
            name="tags"
            placeholder="Enter product tags"
            onSkillsChange={(skills: string[]) => {
              const tags = skills.join(",");
              setState((p: any) => ({ ...p, tags: tags }));
            }}
            value={state["tags"]}
          />
          <p className="small redText removemargin" style={{ marginTop: -10 }}>
            Please use the "enter" key on your keyboard to add a tag
          </p>
        </div>
        {/* image area start */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            width: "45%",
            padding: "1rem",
          }}
        >
          {imagesLocal[0]?.slice(0, 15)}
          <div
            style={{
              display: "flex",
              padding: "0.5rem",
              border: "2px dotted rgba(58, 53, 65, 0.23)",
              borderRadius: "8px",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              position: "relative",
            }}
          >
            <RenderImage
              defaultImg={img1Url || "/products/Image_02.png"}
              imageUrl={type === "edit" ? img1Url : ""}
              imageStyle={{ width: "18rem", maxHeight: "18rem" }}
              alt=""
            />

            <div className="product-edit-image">
              <input
                placeholder="hhhhh"
                type="file"
                accept="image/jpg,image/png,image/jpeg"
                onChange={(e) => handleImageChange(e, 0)}
              />
              <div className="product-edit-image-placeholder">
                <CiImageOn style={{ fontSize: "5rem" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: "#7975B6",
                  }}
                >
                  <FiUploadCloud size={"2rem"} />
                  Upload image
                </div>
                <div style={{ fontSize: "0.75rem" }}>Upload a cover image for your product.</div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <div style={{ fontSize: "0.7rem", fontWeight: "500" }}>Additional Images</div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  padding: "0.5rem",
                  border: "2px dotted rgba(58, 53, 65, 0.23)",
                  borderRadius: "8px",
                  justifyContent: "center",
                  width: "45%",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <RenderImage
                  defaultImg={img2Url || "/products/Image_02.png"}
                  imageUrl={type === "edit" ? img2Url : ""}
                  imageStyle={{ width: "10rem", maxHeight: "10rem" }}
                  alt=""
                />
                <div className="product-edit-image">
                  <input placeholder="" type="file" onChange={(e) => handleImageChange(e, 1)} />
                  <div className="product-edit-image-placeholder">
                    {/* <img alt='img' src={'/products/Image_02.png'} style={{width: '2.5rem'}} /> */}
                    <CiImageOn style={{ fontSize: "2rem" }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1px",
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                        color: "#7975B6",
                      }}
                    >
                      <FiUploadCloud size={"1.5rem"} />
                      Upload image
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  padding: "0.5rem",
                  border: "2px dotted rgba(58, 53, 65, 0.23)",
                  borderRadius: "8px",
                  justifyContent: "center",
                  width: "45%",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <RenderImage
                  defaultImg={img3Url || "/products/Image_02.png"}
                  imageUrl={type === "edit" ? img3Url : ""}
                  imageStyle={{ width: "10rem", maxHeight: "10rem" }}
                  alt=""
                />
                <div className="product-edit-image">
                  <input placeholder="" type="file" onChange={(e) => handleImageChange(e, 2)} />
                  <div className="product-edit-image-placeholder">
                    <CiImageOn style={{ fontSize: "2rem" }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1px",
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                        color: "#7975B6",
                      }}
                    >
                      <FiUploadCloud size={"1.5rem"} />
                      Upload image
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end", textAlign: "center" }}>
            <Button
              label="Save Changes"
              onClick={() => {
                handleCreateProduct();
              }}
            />
          </div>
        </div>
        {/* image area end */}
      </div>
    </div>
  );
};

export default ProductAdd;
