import { useState, useEffect, useContext } from "react";
import "./bankpopup.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";

const Bankpopup = ({ isOpen, setIsOpen, setOpenSuccessModal }) => {
  const { backendServer, usersData, setIsLoading, updateUsersData } = useContext(AppContext);

  const [disableBtn, setDisableBtn] = useState(true);

  const [paystackBanks, setPayStackBank] = useState([]);

  const [bankAccount, setBankAccount] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankCode, setBankCode] = useState("");

  const [primary, setPrimary] = useState(false);

  useEffect(() => {
    if (bankAccount && accountNumber && accountName && bankCode) setDisableBtn(false);
    else setDisableBtn(true);

    if (String(accountNumber).length < 10) setDisableBtn(true);
  });

  useEffect(() => {
    (async () => {
      const { data } = await (await fetch(`https://api.paystack.co/bank`)).json();
      setPayStackBank(
        data.map((el, i) => (
          <MDBDropdownItem
            key={i}
            link
            href="#Settings"
            onClick={() => {
              setBankAccount(el?.name?.trim());
              setBankCode(el?.code);
            }}
          >
            {el?.name}
          </MDBDropdownItem>
        ))
      );
    })();
  }, [isOpen]);

  async function handleSubmit() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/vendor/bank/add/${usersData?.vendor?._id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("vmstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify({
            bank_name: bankAccount,
            account_number: accountNumber,
            account_name: accountName,
            is_primary: primary,
            sort_code: bankCode,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setOpenSuccessModal(true);
      setIsLoading(false);
      updateUsersData();
      setIsOpen(false);
      setBankAccount("");
      setAccountNumber("");
      setAccountName("");
      setBankCode("");
    } catch (err) {
      popup("Something went wrong");
      setIsLoading(false);
    }
  }

  if (!isOpen) return <></>;

  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <button onClick={() => setIsOpen && setIsOpen(false)} className="bankpopup-close-button">
          <AiOutlineCloseCircle className="cursor" size={20} color="#34204C" />
        </button>
        <h3 className="bankpopup-header">Add new withdrawal method</h3>
        <div className="bank-popup-form">
          <MDBDropdown
            id="mdb-dropdown-custom-dropdown"
            style={{ borderRadius: "5px", width: "100%" }}
          >
            <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
              {bankAccount || "Choose Bank"}
            </MDBDropdownToggle>

            <MDBDropdownMenu style={{ width: "inherit", maxHeight: 300, overflow: "auto" }}>
              {paystackBanks}
            </MDBDropdownMenu>
          </MDBDropdown>

          <input
            className="in o-absolute-filter-date-in"
            type="number"
            value={accountNumber}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setAccountNumber(e.target.value)}
            placeholder="Account Number"
            onBlur={() => {
              if (String(accountNumber).length < 10) popup("Invalid Account Number");
            }}
          />

          <input
            className="in o-absolute-filter-date-in"
            type="text"
            value={accountName}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setAccountName(e.target.value)}
            placeholder="Account Name"
          />

          <div className="flex-row align-row-left">
            <p className="small">
              <input
                type="checkbox"
                onChange={() => {}}
                onClick={() => setPrimary(!primary)}
                checked={primary}
              />
              &nbsp; Make this primary account for withdrawals
            </p>
          </div>

          <div className="flex-row">
            <button
              style={{ width: "50%" }}
              disabled={disableBtn}
              className="themeBtn"
              onClick={handleSubmit}
            >
              Add Bank Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bankpopup;
