import React, { useContext, useEffect, useState } from "react";
import "../../styles/index.css";
import "./orders.css";
import { OrdersHome } from "./children/home";
import OrderDetails from "./children/order-details";
import AppContext from "../../context/app-context";

const Orders = function ({ display }) {
  const [screen, setScreen] = useState({
    screen: "home",
    data: null,
  });

  const { setOrdersScreen } = useContext(AppContext);

  useEffect(() => {
    setOrdersScreen({ setScreen });
  }, [display]);

  if (!display) return;

  return (
    <div className="screen-container">
      <OrdersHome
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "home" && true}
      />
      <OrderDetails
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "details" && true}
      />
    </div>
  );
};

export default Orders;
