import React, { useState } from "react";
import closeIcon from "../../../assets/Icon.svg";
import successIcon from "../../../assets/Light.png";
import { AiOutlineCloseCircle } from "react-icons/ai";

import "./second-success.css";

const SecondSuccess = ({ title, text, isModalOpen, handleModalOpen, buttonText, onBtnClick }) => {
  if (!isModalOpen) return <></>;
  return (
    <div className="modal-container">
      <div className="success-container">
        <button onClick={() => handleModalOpen(false)} className="close-button">
          <AiOutlineCloseCircle size={20} color="#34204C" />
        </button>
        <div className="success-title">{title}</div>
        <div className="success-button">
          <img src={successIcon} alt="success-icon" />
        </div>
        <div className="success-text">
          <p>{text}</p>
        </div>
        <button
          type="button"
          onClick={() => {
            onBtnClick && onBtnClick();
          }}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default SecondSuccess;
