import React, { ReactNode, useEffect, useRef, useState } from "react";
import Loading from "../loading/loading";
import "./Table.css";
import { DotsThreeVertical } from "phosphor-react";
import {
  TableHeaderItem,
  isTableHeaderArrayOfTItem,
  removeProperty,
  shortenText,
  transformDataDTO,
} from "./table.utils";
import TableActionCard, {
  IActionCardClick,
} from "./tableActionCard/TableActionCard";
import TablePagination, {
  ITablePagination,
} from "./pagination/TablePagination";
import SearchHeader from "./searchHeader/SearchHeader";
import { SlOptionsVertical } from "react-icons/sl";

type Props = {
  data: object[];
  header: string[] | TableHeaderItem[];
  loading?: boolean;
  placeholder?: string;
  unRenderField?: string[];
  toRenderField?: string[];
  actionOption?: JSX.Element;
  actionOptionIcon?: JSX.Element;
  onActionOptionIconClick?: (row: any) => void;
  columnMaxLen?: number;
  onActionOptionClicks?: IOnActionOptionClicks;
  customTdWrapper?: ITableCustomTdWrapper;
  customTdWrappers?: ITableCustomTdWrapper[];
  headerColumnAction?: IHeaderColumnAction;
  addCheckBox?: boolean;
  addCheckBoxTitle?: string;
  hederStyle?: React.CSSProperties | undefined;
  onChecked?: (rows: any[]) => void;
  customColumn?: {
    position: "start" | "end";
    keyName: string;
    title: (tableData?: any[]) => JSX.Element | string;
    element: (row?: any, index?: number) => JSX.Element | string;
    onClick?: (arg?: { row?: any }) => void;
  }[];
  onRowClick?: (row: any) => void;
  onRowDoubleClick?: (row: any) => void;
  replaceBooleanWithActiveAndInactive?: boolean;
  pagination?: ITablePagination;
  checkAll?: boolean;
  tableTitle?: string;
  searchPlaceholder?: string;
  showSearchBar?: boolean;
  onRefreshIconClick?: () => void;
  capitalizeHeaders?: boolean;
  hidePagination?: boolean;
  searchHeaderMenus?: { right?: JSX.Element[]; left?: JSX.Element[] };
  sn?: boolean;
  snTitle?: string;
};

export type ITableCustomTdWrapper = {
  rowKeyName: string;
  element: (text: any, row?: any) => JSX.Element | any;
};

export type IOnActionOptionClicks = {
  onView?: IActionCardClickTableVersion;
  onEdit?: IActionCardClickTableVersion;
  onSetAsDefault?: IActionCardClickTableVersion;
  onDeactivate?: IActionCardClickTableVersion;
  onDelete?: IActionCardClickTableVersion;
  onOthers?: IActionCardClickTableVersion[];
};

export type IActionCardClickTableVersion = {
  onClick?: (row?: any) => void;
  label?: string;
  color?: string;
  icon?: JSX.Element;
};

type IHeaderColumnAction = {
  icon: string;
  headers: { label: string; onClick: (key: string) => void }[];
};

const Table = ({
  data: __data,
  header,
  unRenderField,
  toRenderField: _toRenderField,
  loading,
  placeholder,
  actionOption,
  actionOptionIcon,
  columnMaxLen = 50,
  onActionOptionClicks,
  customTdWrapper,
  customTdWrappers = customTdWrapper ? [customTdWrapper] : [],
  onActionOptionIconClick,
  headerColumnAction,
  addCheckBox,
  addCheckBoxTitle,
  hederStyle,
  onChecked,
  customColumn,
  onRowClick,
  onRowDoubleClick,
  replaceBooleanWithActiveAndInactive = false,
  checkAll,
  tableTitle,
  pagination,
  showSearchBar,
  searchPlaceholder,
  onRefreshIconClick,
  capitalizeHeaders = false,
  hidePagination = false,
  searchHeaderMenus,
  sn,
  snTitle,
}: Props) => {
  // refs start
  const optionOverlayDivRef = useRef<HTMLDivElement>(null);

  // refs end
  const isTableHeaderArrayOfObject = isTableHeaderArrayOfTItem(header);
  const _data = isTableHeaderArrayOfObject
    ? transformDataDTO(__data, header)
    : __data;

  const newMainData = _data.map((el: any, index: number) => {
    return { ...el, tableDefaultId: index };
  });
  const originalRowsMap = new Map(
    newMainData.map((e) => [e?.tableDefaultId, e])
  );

  const getToRenderColumnIfTableHeaderArrayOfObject = (): string[] => {
    if (!isTableHeaderArrayOfObject) return [];
    const toRenderColumn: string[] = [];
    header.forEach((el) => {
      toRenderColumn.push(el?.key);
    });
    return toRenderColumn;
  };

  const deducedToRenderField =
    _toRenderField || getToRenderColumnIfTableHeaderArrayOfObject();
  const toRenderField = ["tableDefaultId", ...deducedToRenderField];

  const [page, setPage] = useState(pagination?.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(pagination?.rowsPerPage || 10);

  const [data, setData] = useState<any[]>([]);

  const [activeOverlayOption, setActiveOverlayOption] = useState(-1);

  const [activeRow, setActiveRow] = useState(-1);

  const trimAndSetData = (initialData: any[]) => {
    const newData: any[] = [];
    initialData.forEach((el: any) => {
      let newObj: any = {};
      // let valid = false;
      for (const key in el) {
        if (!unRenderField?.includes(key)) {
          newObj[key] = el[key];
        }
      }
      newData.push(newObj);
    });

    // check if toRender
    const temp: any[] = [];
    if (toRenderField && toRenderField.length > 0) {
      newData.forEach((el) => {
        let newObj: any = {};
        // let valid = false;
        for (const key in el) {
          if (toRenderField?.includes(key)) {
            newObj[key] = el[key];
          }
        }
        temp.push(newObj);
      });
      setData(temp);
    } else {
      setData(newData);
    }
  };

  useEffect(() => {
    trimAndSetData(newMainData);
  }, [__data]);

  useEffect(() => {
    const newCheckBoxState = Object.fromEntries(
      _data.map((_, i) => [`row-${i}`, false])
    );
    setCheckBoxState(newCheckBoxState);
  }, [__data]);

  const style = (type: "positive" | "negative"): React.CSSProperties => {
    return {
      backgroundColor:
        type === "positive"
          ? "rgba(0, 255, 34, 0.101)"
          : "rgba(235, 15, 15, 0.153)",
      color:
        type === "positive"
          ? "var(--success-100, #00a341)"
          : "var(--error-100, #C00)",
      textAlign: "center",
    };
  };

  const shortText = (text: string) => {
    return shortenText({ maxLen: columnMaxLen, text: `${text}`, replacer: "..." })
      .text;
  };

  const handleRowClick = (
    index: number,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    e?.stopPropagation();
    if (activeRow === index) {
      setActiveRow(-1);
    } else {
      setActiveRow(index);
    }

    onRowClick && onRowClick(getOriginalRow(index));
  };

  const handleRowDoubleClick = (
    index: number,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    e?.stopPropagation();
    if (activeRow === index) {
      setActiveRow(-1);
    } else {
      setActiveRow(index);
    }

    onRowDoubleClick && onRowDoubleClick(getOriginalRow(index));
  };

  const getOriginalRow = (index: number) => {
    return originalRowsMap.get(index);
  };

  const makeOptions = (row: any) => {
    const res: IActionCardClick[] = [];

    const suppliedActions = onActionOptionClicks?.onOthers;
    if (suppliedActions) {
      suppliedActions.forEach((el) => {
        res.push({
          label: el.label || "",
          icon: el.icon || <></>,
          onClick: () => {
            el.onClick && el.onClick(row);
            setActiveOverlayOption(-1);
          },
          color: el?.color || "",
        });
      });
    }
    return res;
  };

  const [checkBoxState, setCheckBoxState] = useState<any | {}>({});
  const [selected, setSelected] = useState<any[]>([]);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      optionOverlayDivRef.current &&
      !optionOverlayDivRef.current.contains(target)
    ) {
      setActiveOverlayOption(-1);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {showSearchBar && (
        <div style={{ marginBottom: "1rem" }}>
          <SearchHeader
            data={data}
            mainData={newMainData}
            onSearchResult={(res) => {
              trimAndSetData(res);
            }}
            dataToPrint={
              selected.length > 0
                ? removeProperty(selected, "tableDefaultId")
                : removeProperty(data, "tableDefaultId")
            }
            excelTitle={tableTitle}
            searchPlaceholder={searchPlaceholder}
            onRefreshIconClick={() =>
              onRefreshIconClick && onRefreshIconClick()
            }
            searchHeaderMenus={searchHeaderMenus}
          />
        </div>
      )}
      <div className="table-container">
        <table className="table">
          <thead className="table-thead" style={{ ...hederStyle }}>
            <tr className="table-thead-tr">
              {addCheckBox && (
                <th
                  className={`table-th ${
                    capitalizeHeaders && " table-th-to-uppercase"
                  }`}
                >
                  {checkAll ? (
                    <input
                      className="table-checkbox"
                      checked={
                        selected.length > 0 && selected.length === data.length
                      }
                      onChange={() => {
                        const status = selected.length === data.length;
                        const allRow = Object.fromEntries(
                          data.map((_, i) => [`row-${i}`, !status])
                        );
                        const updated = !status ? data : [];
                        setSelected(updated);
                        setCheckBoxState((p: any) => ({ ...p, ...allRow }));
                        onChecked && onChecked(updated);
                      }}
                      type="checkbox"
                    />
                  ) : (
                    addCheckBoxTitle || ""
                  )}
                </th>
              )}

              {sn && (
                <th
                  className={`table-th ${
                    capitalizeHeaders && " table-th-to-uppercase"
                  }`}
                  style={{minWidth: "5rem"}}
                  key={"sn"}
                >
                  <>
                  {snTitle} S/N
                  </>
                </th>
              )}
              {customColumn &&
                customColumn.length > 0 &&
                customColumn.map(
                  (ctH, ctH_key) =>
                    ctH.position === "start" && (
                      <th
                        className={`table-th ${
                          capitalizeHeaders && " table-th-to-uppercase"
                        }`}
                        key={ctH_key}
                      >
                        {ctH.title(newMainData)}
                      </th>
                    )
                )}
              {header &&
                header.map((headerItem, i) => {
                  let x;
                  let hasMinWith = "";
                  if (isTableHeaderArrayOfObject) {
                    const _headerItem = headerItem as TableHeaderItem;
                    x = _headerItem?.label;
                    hasMinWith = _headerItem?.minWidth;
                  } else {
                    x = headerItem as string;
                  }
                  return (
                    <th
                      className={`table-th ${
                        capitalizeHeaders && " table-th-to-uppercase"
                      }`}
                      style={{
                        fontFamily: "Sora !important",
                        whiteSpace: "nowrap",
                        minWidth: hasMinWith || "auto",
                      }}
                      key={i}
                    >
                      {x}
                    </th>
                  );
                })}
              {customColumn &&
                customColumn.length > 0 &&
                customColumn.map(
                  (ctH, ctH_key) =>
                    ctH.position === "end" && (
                      <th
                        className={`table-th ${
                          capitalizeHeaders && " table-th-to-uppercase"
                        }`}
                        key={ctH_key}
                      >
                        {ctH.title(data)}
                      </th>
                    )
                )}
              {(actionOption || onActionOptionClicks) && (
                <th
                  className={`table-th ${
                    capitalizeHeaders && " table-th-to-uppercase"
                  }`}
                ></th>
              )}
            </tr>
          </thead>
          {loading ? (
            <></>
          ) : (
            <tbody>
              {
              (hidePagination? data: data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ).map((_row: any) => {
                  const { tableDefaultId, ...row } = _row;
                  const rowIndex = tableDefaultId;
                  return (
                    <tr
                      className="table-tbody-tr"
                      style={{
                        background:
                          activeRow === rowIndex ? "var(--black-0, #FFF)" : "",
                      }}
                      onClick={(e) => {
                        handleRowClick(rowIndex, e);
                      }}
                      onDoubleClick={(e) => handleRowDoubleClick(rowIndex, e)}
                      key={rowIndex}
                    >
                      {addCheckBox && (
                        <td
                          className="table-tbody-tr-td"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <input
                            className="table-checkbox"
                            checked={checkBoxState[`row-${rowIndex}`]}
                            onChange={(e) => {
                              e.stopPropagation();
                              const rowCheckName = `row-${rowIndex}`;
                              const status =
                                checkBoxState[rowCheckName] === undefined
                                  ? false
                                  : !checkBoxState[rowCheckName];
                              const originalRow = { ...row, tableDefaultId };
                              const updated = status
                                ? [...selected, originalRow]
                                : selected.filter(
                                    (e) => e?.tableDefaultId !== tableDefaultId
                                  );
                              setSelected(updated);
                              setCheckBoxState((p: any) => ({
                                ...p,
                                [rowCheckName]: status,
                              }));
                              onChecked && onChecked(updated);
                            }}
                            type="checkbox"
                          />
                        </td>
                      )}

                      {sn && <td>{rowIndex + 1}</td>}

                      {customColumn &&
                        customColumn.length > 0 &&
                        customColumn.map(
                          (ctb, ctb_key) =>
                            ctb.position === "start" && (
                              <td className="table-tbody-tr-td" key={ctb_key}>
                                {ctb.element(
                                  {
                                    ...getOriginalRow(rowIndex),
                                    [ctb.keyName]:
                                      typeof ctb.element() === "string"
                                        ? ctb.element()
                                        : "",
                                  },
                                  rowIndex
                                )}
                              </td>
                            )
                        )}
                      {Object.values(row).map((tdValue: any, index: number) =>
                        customTdWrappers &&
                        customTdWrappers.length > 0 &&
                        customTdWrappers.find(
                          (eTdInner) => row[eTdInner.rowKeyName] === tdValue
                        ) ? (
                          <td className="table-tbody-tr-td" key={index}>
                            {customTdWrappers
                              .find(
                                (eTdInner) =>
                                  row[eTdInner.rowKeyName] === tdValue
                              )
                              ?.element(tdValue, getOriginalRow(rowIndex))}
                          </td>
                        ) : typeof tdValue === "boolean" &&
                          replaceBooleanWithActiveAndInactive ? (
                          <td className="table-tbody-tr-td" key={index}>
                            <div
                              className="table-active-pill"
                              style={style(tdValue ? "positive" : "negative")}
                            >
                              <div className="table-active-pill-text">
                                <div className="table-active-pill-text-active">
                                  {tdValue ? "Active" : "inactive"}
                                </div>
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            title={tdValue}
                            className="table-tbody-tr-td"
                            onClick={() =>
                              onRowClick && onRowClick(getOriginalRow(rowIndex))
                            }
                            key={index}
                          >
                            {shortText(tdValue)}
                          </td>
                        )
                      )}
                      {/* Custom column */}
                      {customColumn &&
                        customColumn.length > 0 &&
                        customColumn.map(
                          (ctb, ctb_key) =>
                            ctb.position === "end" && (
                              <td
                                className="table-tbody-tr-td"
                                style={{ zIndex: 2 }}
                                key={ctb_key}
                              >
                                {ctb.element(
                                  {
                                    ...getOriginalRow(rowIndex),
                                    [ctb.keyName]:
                                      typeof ctb.element() === "string"
                                        ? ctb.element()
                                        : "",
                                  },
                                  rowIndex
                                )}
                              </td>
                            )
                        )}
                      {/* Custom column */}
                      {(actionOption || onActionOptionClicks) &&
                        (actionOption ||
                          (onActionOptionClicks && (
                            <td
                              className="table-tbody-tr-td"
                              style={{
                                position: "relative",
                                overflow: "visible",
                              }}
                            >
                              <div
                                onBlur={(e) => {
                                  console.log("blur");
                                }}
                                style={{
                                  cursor: "pointer",
                                  position: "relative",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (activeOverlayOption === rowIndex) {
                                    setActiveOverlayOption(-1);
                                  } else {
                                    setActiveOverlayOption(rowIndex);
                                  }
                                }}
                              >
                                {(actionOptionIcon && (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (activeOverlayOption === rowIndex) {
                                        setActiveOverlayOption(-1);
                                      } else {
                                        setActiveOverlayOption(rowIndex);
                                      }
                                      onActionOptionIconClick &&
                                        onActionOptionIconClick(row);
                                    }}
                                  >
                                    {actionOptionIcon}
                                  </div>
                                )) || <SlOptionsVertical size={"1rem"} />}
                              </div>
                              {activeOverlayOption === rowIndex &&
                                (onActionOptionClicks?.onView?.label ||
                                  onActionOptionClicks?.onEdit?.label ||
                                  onActionOptionClicks?.onDelete?.label ||
                                  onActionOptionClicks?.onSetAsDefault?.label ||
                                  onActionOptionClicks?.onDeactivate?.label ||
                                  (onActionOptionClicks?.onOthers &&
                                    onActionOptionClicks?.onOthers[0]
                                      .label)) && (
                                  <div
                                    ref={optionOverlayDivRef}
                                    className="table-tbody-tr-td-action-option"
                                  >
                                    <TableActionCard
                                      onView={{
                                        label:
                                          onActionOptionClicks.onView?.label ||
                                          "",
                                        onClick: () => {
                                          onActionOptionClicks.onView
                                            ?.onClick &&
                                            onActionOptionClicks.onView?.onClick(
                                              getOriginalRow(rowIndex)
                                            );
                                          setActiveOverlayOption(-1);
                                        },
                                        color:
                                          onActionOptionClicks?.onView?.color ||
                                          "",
                                      }}
                                      onEdit={
                                        onActionOptionClicks?.onEdit?.label
                                          ? {
                                              label:
                                                onActionOptionClicks.onEdit
                                                  ?.label || "",
                                              onClick: () => {
                                                onActionOptionClicks.onEdit
                                                  ?.onClick &&
                                                  onActionOptionClicks.onEdit?.onClick(
                                                    getOriginalRow(rowIndex)
                                                  );
                                                setActiveOverlayOption(-1);
                                              },
                                              color:
                                                onActionOptionClicks?.onEdit
                                                  ?.color || "",
                                            }
                                          : undefined
                                      }
                                      onSetAsDefault={{
                                        label:
                                          onActionOptionClicks.onSetAsDefault
                                            ?.label || "",
                                        onClick: () => {
                                          onActionOptionClicks.onEdit
                                            ?.onClick &&
                                            onActionOptionClicks.onEdit?.onClick(
                                              getOriginalRow(rowIndex)
                                            );
                                          setActiveOverlayOption(-1);
                                        },
                                        color:
                                          onActionOptionClicks?.onSetAsDefault
                                            ?.color || "",
                                      }}
                                      onDeactivate={{
                                        label:
                                          onActionOptionClicks.onDeactivate
                                            ?.label || "",
                                        onClick: () => {
                                          onActionOptionClicks.onEdit
                                            ?.onClick &&
                                            onActionOptionClicks.onEdit?.onClick(
                                              getOriginalRow(rowIndex)
                                            );
                                          setActiveOverlayOption(-1);
                                        },
                                        color:
                                          onActionOptionClicks?.onDeactivate
                                            ?.color || "",
                                      }}
                                      onDelete={{
                                        label:
                                          onActionOptionClicks.onDelete
                                            ?.label || "",
                                        onClick: () => {
                                          onActionOptionClicks.onEdit
                                            ?.onClick &&
                                            onActionOptionClicks.onEdit?.onClick(
                                              getOriginalRow(rowIndex)
                                            );
                                          setActiveOverlayOption(-1);
                                        },
                                        color:
                                          onActionOptionClicks?.onDelete
                                            ?.color || "",
                                      }}
                                      others={makeOptions(
                                        getOriginalRow(rowIndex)
                                      )}
                                    />
                                  </div>
                                )}
                            </td>
                          )))}
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
        {loading && (
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              display: "flex",
              paddingTop: "3rem",
              paddingBottom: "3rem",
              top: "2.6rem",
            }}
          >
            loading..
          </div>
        )}
        {placeholder && !newMainData.length && (
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              display: "flex",
              paddingTop: "3rem",
              paddingBottom: "3rem",
              top: "2.6rem",
            }}
          >
            {placeholder}
          </div>
        )}
        <div
          style={{
            display: !hidePagination ? "flex" : "none",
            justifyContent: "center",
            width: "100%",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            top: "2.6rem",
          }}
        >
          <TablePagination
            totalDataCount={pagination?.totalDataCount || newMainData.length}
            // onDataChange={(res) => trimAndSetData(res)}
            onPageChange={(val) => setPage(val)}
            onRowsPerPageChange={(val) => setRowsPerPage(val)}
            initialData={newMainData}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        </div>
      </div>
    </>
  );
};

export default Table;
