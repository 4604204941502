import React from "react";
import "./filters.css";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const StatusFilter = ({
  isOpen,
  setIsOpen,
  setTableFIlterStatus,
  tableFilterStatus,
  setTableDateFilter,
  tableDateFilter,
  runTableFilter,
  getData,
}) => {
  if (!isOpen) return;
  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="filter-container">
          <div className="filter-headings">
            <div className="filter-text">Filter</div>
            <p
              className="clear-all cursor"
              onClick={() => {
                setTableDateFilter({
                  from: "",
                  to: "",
                });
                setTableFIlterStatus("");
                getData();
              }}
            >
              clear all
            </p>
          </div>
          <div className="form-filters">
            <div className="filter-date">
              <p className="small removemargin">Status</p>
              <MDBDropdown
                id="mdb-dropdown-custom-dropdown"
                style={{ width: "350px", borderRadius: "5px" }}
              >
                <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                  {tableFilterStatus === "processing"
                    ? "packed"
                    : tableFilterStatus === "completed"
                    ? "delivered"
                    : tableFilterStatus === "CANCELLED"
                    ? "returned"
                    : tableFilterStatus || "Select Status"}
                </MDBDropdownToggle>

                <MDBDropdownMenu style={{ width: "350px" }}>
                  <MDBDropdownItem
                    link
                    href="#Orders"
                    onClick={(e) => {
                      setTableFIlterStatus(e.target.textContent.toLowerCase());
                    }}
                  >
                    Pending
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Orders"
                    onClick={(e) => {
                      setTableFIlterStatus("completed");
                    }}
                  >
                    Delivered
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Orders"
                    onClick={(e) => {
                      setTableFIlterStatus("processing");
                    }}
                  >
                    Packed
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Orders"
                    onClick={(e) => {
                      setTableFIlterStatus(e.target.textContent.toLowerCase());
                    }}
                  >
                    Shipped
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Orders"
                    onClick={(e) => {
                      setTableFIlterStatus("CANCELLED");
                    }}
                  >
                    Returned
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>

            <div className="filter-date">
              <p className="small removemargin">From</p>
              <input
                style={{ borderRadius: 5, padding: 10, border: "1px solid rgb(201, 199, 199)" }}
                className="o-absolute-filter-date-in"
                type="date"
                value={tableDateFilter.from}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setTableDateFilter({
                    from: e.target.value,
                    to: tableDateFilter?.to,
                  });
                }}
              />
            </div>

            <div className="filter-date">
              <p className="small removemargin">To</p>
              <input
                style={{ borderRadius: 5, padding: 10, border: "1px solid rgb(201, 199, 199)" }}
                className="o-absolute-filter-date-in"
                type="date"
                value={tableDateFilter.to}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setTableDateFilter({
                    to: e.target.value,
                    from: tableDateFilter?.from,
                  });
                }}
              />
            </div>

            <div className="filter-input-button" style={{ paddingTop: 20 }}>
              <button
                disabled={
                  (tableDateFilter?.to && tableDateFilter?.from) || tableFilterStatus ? false : true
                }
                className="themeBtn"
                onClick={runTableFilter}
              >
                Apply Filter
              </button>
            </div>
          </div>
          <div className="cancel-div">
            <button
              className="cancel"
              onClick={() => {
                setIsOpen(false);
                setTableDateFilter({
                  from: "",
                  to: "",
                });
                setTableFIlterStatus("");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusFilter;
