import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import search from "../../assets/search.png";
import product from "../../assets/Customers.png";
import CreditCard from "../../assets/credit-card.png";
import shoppingCart from "../../assets/shopping-cart.png";
import "./support.css";
import { BsArrowLeftShort } from "react-icons/bs";
import { FaPlus, FaMinus } from "react-icons/fa";
import { productFaq, ordersFaq, walletFaq } from "./faq";

const Support = function ({ display }) {
  const [supportData, setSupportData] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("Popular Questions");
  const [toggleTopicView, setToggleTopicView] = useState(true);
  const [searchValue, setSearchValue] = useState("all");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);

    // setSearchData(searchData?.filter((item) => item?.question?.toLowerCase()?.indexOf(e.target.value.toLowerCase()) >=0))
    setSearchData(
      searchData.filter((item) =>
        item?.question?.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  // if (!display) return;

  const handleSupportData = (data) => {
    setSupportData(data?.category);
    setSelectedTopic(data);

    setToggleTopicView(false);
  };

  const backReset = () => {
    setSupportData("");
    setSelectedTopic("Popular Questions");
    setToggleTopicView(true);
    setSearchData(faqData);
  };

  useEffect(() => {
    setSearchData(faqData);
  }, []);

  function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAnswer = (e) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    };

    return (
      // <div className="faq-item">
      //   <div className="faq-question" onClick={toggleAnswer}>
      //     <div className="question">{question}</div>
      //     <button className="faq-toggle-icon">{isOpen ? <FaMinus color='#3A354180'/> : <FaPlus color='#3A354180'/>}</button>
      //   </div>
      //   {isOpen === true && <div className="faq-answer">{answer}</div>}
      // </div>
      <div className={`faq-item ${isOpen ? "open" : ""}`}>
        <div className="faq-question" onClick={toggleAnswer}>
          <div className="question">{question}</div>
          <button className="faq-toggle-icon">
            {isOpen ? <FaMinus color="#3A354180" /> : <FaPlus color="#3A354180" />}
          </button>
        </div>
        <div className={`faq-answer ${isOpen ? "open" : ""}`}>
          {isOpen && <div className="answer-content">{answer}</div>}
        </div>
      </div>
    );
  }

  const supportContent = [
    {
      id: 1,
      category: "Products",
      description: "Check out all questions related to products",
      icon: product,
    },

    {
      id: 2,
      category: "Orders",
      description: "Check out all questions related to orders",
      icon: shoppingCart,
    },

    {
      id: 3,
      category: "Wallet",
      description: "Check out all questions related to wallet",
      icon: CreditCard,
    },
  ];

  const faqData = [...productFaq, ...ordersFaq, ...walletFaq];

  const [searchData, setSearchData] = useState(productFaq);

  useEffect(() => {
    if (searchValue === "" && supportData === "Products") {
      setSearchData(productFaq);
    } else if (searchValue === "" && supportData === "Orders") {
      setSearchData(ordersFaq);
    } else if (searchValue === "" && supportData === "Wallet") {
      setSearchData(walletFaq);
    } else if (searchValue === "") {
      setSearchData(faqData);
    } else {
      const filteredData = searchData.filter((item) => {
        return item.question.toLowerCase().includes(searchValue.toLowerCase());
      });
      setSearchData(filteredData);
    }
  }, [supportData, searchValue]);

  useEffect(() => {
    if (supportData === "Products") {
      setSearchData(productFaq);
    } else if (supportData === "Orders") {
      setSearchData(ordersFaq);
    } else if (supportData === "Wallet") {
      setSearchData(walletFaq);
    } else {
      setSearchData(faqData);
    }
  }, [supportData]);

  const SelectedTopic = ({ selected }) => (
    <div className="selected-topic">
      <img src={selected?.icon} alt="product-logo" />
      <div>
        <div className="category">{selected?.category}</div>
        <div className="text">{selected?.description}</div>
      </div>
    </div>
  );

  if (!display) return;

  return (
    <div className="screen-container-support screen-container" style={{ padding: 30 }}>
      <div className="support-heading">
        <div>
          {supportData !== "" ? (
            <button onClick={backReset} className="back-button">
              <BsArrowLeftShort size={30} />
            </button>
          ) : null}
        </div>
        <div className="FAQ">Frequently Asked Questions</div>
      </div>
      <div className="support-search">
        <div className="search-icon">
          <img src={search} alt="" />
        </div>
        <input
          onChange={handleSearch}
          className=""
          type="text"
          placeholder="Search frequently asked questions"
        />
      </div>
      {toggleTopicView && (
        <div className="support-categories">
          {supportContent.map((item, i) => (
            <button key={i} onClick={() => handleSupportData(item)} className="support-category">
              <img src={item?.icon} alt={item?.icon} />
              <div className="support-category-title">{item?.category}</div>
              <div className="support-description">{item?.description}</div>
            </button>
          ))}
        </div>
      )}
      {selectedTopic === "Popular Questions" && (
        <div>
          <strong>Popular Questions</strong>
        </div>
      )}
      {selectedTopic !== "Popular Questions" && <SelectedTopic selected={selectedTopic} />}
      <div className="faq-container">
        {searchData?.map((faq, index) => (
          <FAQItem key={index} question={faq?.question} answer={faq?.answer} />
        ))}
      </div>
      <div className="support-contact" style={{ marginTop: 30, borderRadius: 5 }}>
        <div className="contact-support-heading">CAN'T FIND WHAT YOU'RE LOOKING FOR?</div>
        <div className="contact-support-text">Reach out to our team and get immediate support</div>
        <div>
          <button
            onClick={() =>
              window.open("https://estility.atlassian.net/servicedesk/customer/portal/3", "_blank")
            }
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Support;
