import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./dashboard.css";
import AppContext from "../../context/app-context";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDown } from "react-icons/ai";
import { popup } from "../../vanilla-functions/model";
import {
  OrdersTable,
  pseudoCheckBoxSelectedData,
} from "../../components/orders-table/orders-table";
import { AiOutlineArrowRight } from "react-icons/ai";
import DStoreFilterModal from "../../components/filter-modals/dashboard/store-sales";
import DOrderFilterModal from "../../components/filter-modals/dashboard/orders";
import { CiSearch } from "react-icons/ci";
import CarouselNotification from "../../components/carousel-broadcast/carousel-broadcast";
import d1 from "../../assets/dashboard/d-1.svg";
import d2 from "../../assets/dashboard/d-2.svg";

const filterResultsRaw = {
  storeSalesFilterResults: "",
  ordersFilterResults: {
    delivered: "",
    pending: "",
    returned: "",
  },
  totals: {
    storeSales: "",
    orders: "",
    delivered: "",
    pending: "",
    returned: "",
  },
};

const Dashboard = function ({ display }) {
  const { backendServer, setIsLoading, usersData, shortenNumber, patternMatching, ordersScreen } =
    useContext(AppContext);
  const [showStoreSales, setShowStoreSales] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [page, setPage] = useState(0);
  const [oringinalData, setOringinalData] = useState([]);
  const [tablePendingData, setTablePendingData] = useState([]);

  const [searchQuery, setSearcQuery] = useState("");

  const [filterModals, setFilterModals] = useState({
    storeSales: {
      display: false,
    },
    orders: {
      display: false,
    },
  });
  const [statFilters, setStatFilters] = useState({
    storeSales: {
      from: "",
      to: "",
    },
    orders: {
      from: "",
      to: "",
    },
  });
  const [_filterResults, setFilterResults] = useState({});

  const [circleStatValue, setcircleStatValue] = useState(0);
  const [circleStatClass, setcircleStatClass] = useState("");

  useEffect(() => {
    getData();
  }, [usersData]);

  function calcCircleStat(data) {
    const numberOfDeliveredOrders = data.filter((e) => {
      return e.status === "completed";
    }).length;

    const divided = data.length / 4;

    if (numberOfDeliveredOrders <= divided && numberOfDeliveredOrders > 0) {
      setcircleStatClass("circle-complete-25perc");
      setcircleStatValue("25%");
    }
    if (numberOfDeliveredOrders >= divided && numberOfDeliveredOrders <= divided * 2) {
      setcircleStatClass("circle-complete-50perc");
      setcircleStatValue("50%");
    }

    if (numberOfDeliveredOrders >= divided * 2 && numberOfDeliveredOrders <= divided * 3) {
      setcircleStatClass("circle-complete-75perc");
      setcircleStatValue("75%");
    }

    if (numberOfDeliveredOrders >= divided * 3 && numberOfDeliveredOrders <= divided * 4) {
      setcircleStatClass("circle-complete-100perc");
      setcircleStatValue("100%");
    }
  }

  async function getData() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#o-checkbox-parent"))
      document.querySelector("#o-checkbox-parent").checked = false;
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/order/vendor/get/${usersData?.vendor?._id}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("vmstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const theReversed = [...returned?.data];
      theReversed.reverse();

      calcCircleStat(theReversed);
      setOrderData(theReversed);
      setOringinalData(theReversed);
      setTablePendingData(theReversed?.filter((e) => e.status === "pending"));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching vendor orders");
    }
  }

  async function runDateFilter(field, from, to, yesterdayy, todayy) {
    if (!field) return;

    if (field === "store-sales") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        setStatFilters({
          storeSales: {
            from: new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(yesterday)),
            to: statFilters.storeSales?.to,
          },
          orders: statFilters.orders,
        });

        let total = 0;

        oringinalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(yesterday))
            )
              return e;
          })
          ?.filter((e) => e.status === "completed")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        setFilterResults({});

        filterResultsRaw.storeSalesFilterResults = String(total);
        filterResultsRaw.totals.storeSales = String(
          oringinalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === "completed")?.length
        );
      } else if (todayy) {
        const today = new Date(Date.now());

        setStatFilters({
          storeSales: {
            from: new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(today)),
            to: statFilters.storeSales?.to,
          },
          orders: statFilters.orders,
        });

        let total = 0;

        oringinalData
          .filter((e) => {
            if (
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(e.createdAt)) ===
              new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(today))
            )
              return e;
          })
          ?.filter((e) => e.status === "completed")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        setFilterResults({});

        filterResultsRaw.storeSalesFilterResults = String(total);
        filterResultsRaw.totals.storeSales = String(
          oringinalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === "completed")?.length
        );
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        let total = 0;

        oringinalData
          .filter((e) => {
            if (
              dateRanges.includes(
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt))
              )
            )
              return e;
          })
          ?.filter((e) => e.status === "completed")
          ?.forEach((e) => {
            total = total + e?.totalAmount;
          });

        setFilterResults({});

        filterResultsRaw.storeSalesFilterResults = String(total);
        filterResultsRaw.totals.storeSales = String(
          oringinalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === "completed")?.length
        );
      } else popup("Incomplete date range");
    } else if (field === "orders") {
      if (yesterdayy) {
        const yesterday = new Date(new Date().getTime());
        yesterday.setDate(new Date().getDate() - 1);

        setStatFilters({
          storeSales: statFilters.storeSales,
          orders: {
            from: new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(yesterday)),
            to: statFilters?.orders?.to,
          },
        });

        const sortOutData = (type) => {
          let total = 0;

          oringinalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(yesterday))
              )
                return e;
            })
            ?.filter((e) => e.status === type)
            ?.forEach((e) => {
              total = total + e?.totalAmount;
            });

          if (type === "completed") {
            setFilterResults({});

            filterResultsRaw.ordersFilterResults.delivered = String(total);
            filterResultsRaw.totals.orders = String(
              oringinalData.filter((e) => {
                if (
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt)) ===
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(yesterday))
                )
                  return e;
              })?.length
            );
            filterResultsRaw.totals.delivered = String(
              oringinalData
                .filter((e) => {
                  if (
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(e.createdAt)) ===
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(yesterday))
                  )
                    return e;
                })
                ?.filter((e) => e.status === type)?.length
            );
          } else if (type === "pending") {
            setFilterResults({});

            filterResultsRaw.ordersFilterResults.pending = String(total);
            filterResultsRaw.totals.orders = String(
              oringinalData.filter((e) => {
                if (
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt)) ===
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(yesterday))
                )
                  return e;
              })?.length
            );
            filterResultsRaw.totals.pending = String(
              oringinalData
                .filter((e) => {
                  if (
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(e.createdAt)) ===
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(yesterday))
                  )
                    return e;
                })
                ?.filter((e) => e.status === type)?.length
            );
          } else if (type === "CANCELLED") {
            setFilterResults({});

            filterResultsRaw.ordersFilterResults.returned = String(total);
            filterResultsRaw.totals.orders = String(
              oringinalData.filter((e) => {
                if (
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt)) ===
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(yesterday))
                )
                  return e;
              })?.length
            );
            filterResultsRaw.totals.returned = String(
              oringinalData
                .filter((e) => {
                  if (
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(e.createdAt)) ===
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(yesterday))
                  )
                    return e;
                })
                ?.filter((e) => e.status === type)?.length
            );
          }
        };

        sortOutData("completed");
        sortOutData("pending");
        sortOutData("CANCELLED");
      } else if (todayy) {
        const today = new Date(Date.now());

        setStatFilters({
          storeSales: statFilters.storeSales,
          orders: {
            from: new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(today)),
            to: statFilters?.orders?.to,
          },
        });

        const sortOutData = (type) => {
          let total = 0;

          oringinalData
            .filter((e) => {
              if (
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(e.createdAt)) ===
                new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(today))
              )
                return e;
            })
            ?.filter((e) => e.status === type)
            ?.forEach((e) => {
              total = total + e?.totalAmount;
            });

          if (type === "completed") {
            setFilterResults({});

            filterResultsRaw.ordersFilterResults.delivered = String(total);
            filterResultsRaw.totals.orders = String(
              oringinalData.filter((e) => {
                if (
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt)) ===
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(today))
                )
                  return e;
              })?.length
            );
            filterResultsRaw.totals.delivered = String(
              oringinalData
                .filter((e) => {
                  if (
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(e.createdAt)) ===
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(today))
                  )
                    return e;
                })
                ?.filter((e) => e.status === type)?.length
            );
          } else if (type === "pending") {
            setFilterResults({});

            filterResultsRaw.ordersFilterResults.pending = String(total);
            filterResultsRaw.totals.orders = String(
              oringinalData.filter((e) => {
                if (
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt)) ===
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(today))
                )
                  return e;
              })?.length
            );
            filterResultsRaw.totals.pending = String(
              oringinalData
                .filter((e) => {
                  if (
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(e.createdAt)) ===
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(today))
                  )
                    return e;
                })
                ?.filter((e) => e.status === type)?.length
            );
          } else if (type === "CANCELLED") {
            setFilterResults({});

            filterResultsRaw.ordersFilterResults.returned = String(total);
            filterResultsRaw.totals.orders = String(
              oringinalData.filter((e) => {
                if (
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt)) ===
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(today))
                )
                  return e;
              })?.length
            );
            filterResultsRaw.totals.returned = String(
              oringinalData
                .filter((e) => {
                  if (
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(e.createdAt)) ===
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(today))
                  )
                    return e;
                })
                ?.filter((e) => e.status === type)?.length
            );
          }
        };

        sortOutData("completed");
        sortOutData("pending");
        sortOutData("CANCELLED");
      } else if (from && to) {
        const startDate = new Date(from);

        const endDate = new Date(to);

        const datesArray = [];

        // loop from start date to end date
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          datesArray.push(new Date(date));
        }

        const dateRanges = datesArray.map((e) =>
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e))
        );

        const sortOutData = (type) => {
          let total = 0;

          oringinalData
            .filter((e) => {
              if (
                dateRanges.includes(
                  new Intl.DateTimeFormat("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(e.createdAt))
                )
              )
                return e;
            })
            ?.filter((e) => e.status === type)
            ?.forEach((e) => {
              total = total + e?.totalAmount;
            });

          if (type === "completed") {
            setFilterResults({});
            filterResultsRaw.ordersFilterResults.delivered = String(total);
            filterResultsRaw.totals.orders = String(
              oringinalData.filter((e) => {
                if (
                  dateRanges.includes(
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(e.createdAt))
                  )
                )
                  return e;
              })?.length
            );
            filterResultsRaw.totals.delivered = String(
              oringinalData
                .filter((e) => {
                  if (
                    dateRanges.includes(
                      new Intl.DateTimeFormat("en-us", {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(e.createdAt))
                    )
                  )
                    return e;
                })
                ?.filter((e) => e.status === type)?.length
            );
          } else if (type === "pending") {
            setFilterResults({});

            filterResultsRaw.ordersFilterResults.pending = String(total);
            filterResultsRaw.totals.orders = String(
              oringinalData.filter((e) => {
                if (
                  dateRanges.includes(
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(e.createdAt))
                  )
                )
                  return e;
              })?.length
            );
            filterResultsRaw.totals.pending = String(
              oringinalData
                .filter((e) => {
                  if (
                    dateRanges.includes(
                      new Intl.DateTimeFormat("en-us", {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(e.createdAt))
                    )
                  )
                    return e;
                })
                ?.filter((e) => e.status === type)?.length
            );
          } else if (type === "CANCELLED") {
            setFilterResults({});
            filterResultsRaw.ordersFilterResults.returned = String(total);
            filterResultsRaw.totals.orders = String(
              oringinalData.filter((e) => {
                if (
                  dateRanges.includes(
                    new Intl.DateTimeFormat("en-us", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(e.createdAt))
                  )
                )
                  return e;
              })?.length
            );

            filterResultsRaw.totals.returned = String(
              oringinalData
                .filter((e) => {
                  if (
                    dateRanges.includes(
                      new Intl.DateTimeFormat("en-us", {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(e.createdAt))
                    )
                  )
                    return e;
                })
                ?.filter((e) => e.status === type)?.length
            );
          }
        };

        sortOutData("completed");
        sortOutData("pending");
        sortOutData("CANCELLED");
      } else popup("Incomplete date range");
    }

    setFilterModals({
      storeSales: {
        display: false,
      },
      orders: {
        display: false,
      },
    });
  }

  function searchPlease(e) {
    setPage(0);
    setSearcQuery(e.target.value);

    const query = e.target.value;

    if (!query) return getData();

    const foundOrders = tablePendingData?.filter((e) => {
      return (
        patternMatching(query, e?._id) ||
        patternMatching(query, e?.rider?.first_name) ||
        patternMatching(query, e?.rider?.last_name)
      );
    });

    setTablePendingData(foundOrders);
  }

  if (!display) return;

  return (
    <div className="screen-container">
      <div className="flex-row" style={{ padding: "0px 15px 0px 15px" }}>
        <CarouselNotification
          data={usersData?.vendor?.notifications?.filter(
            (e) => e.notification_type === "broadcasts"
          )}
        />
      </div>
      <div className="dashboard-stat-container flex-row">
        <div className="d-each-d-stat-container d-each-d-stat-container-one">
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={d1} alt="estility-finances" />
              <p className="small blackText removemargin"> Store Sales</p>
              <span className="xsmall removemargin">
                (
                {filterResultsRaw?.totals?.storeSales ||
                  orderData?.filter((e) => e.status === "completed")?.length}
                )
              </span>
            </div>

            <div
              className="xsmall removemargin cursor no-hover"
              onClick={() => {
                setFilterModals({
                  storeSales: {
                    display: true,
                  },
                  orders: {
                    display: false,
                  },
                });
              }}
            >
              {statFilters?.storeSales?.from || statFilters?.storeSales?.to ? "Custom" : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <DStoreFilterModal
                display={filterModals?.storeSales?.display}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin" style={{ paddingLeft: 34 }}>
              {showStoreSales ? (
                <span className="big d-stat-text boldText">
                  ₦
                  {shortenNumber(filterResultsRaw?.storeSalesFilterResults) ||
                    (() => {
                      let total = 0;
                      orderData
                        ?.filter((e) => e.status === "completed")
                        ?.forEach((e) => {
                          total = total + e?.totalAmount;
                        });
                      return shortenNumber(total);
                    })() ||
                    "-"}
                </span>
              ) : (
                <span className="big d-stat-text">******</span>
              )}
            </p>

            <p className="small removemargin">
              <span className="flex-row" style={{ justifyContent: "flex-end", marginTop: -5 }}>
                {!showStoreSales ? (
                  <AiOutlineEye className="cursor" onClick={() => setShowStoreSales(true)} />
                ) : (
                  <AiOutlineEyeInvisible
                    className="cursor"
                    onClick={() => setShowStoreSales(false)}
                  />
                )}
              </span>
            </p>
          </div>
        </div>

        <div className="d-each-d-stat-container d-each-d-stat-container-two">
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src={d2} alt="estility-finances" />
              <p className="small blackText removemargin">Orders{"  "}</p>
              <span className="xsmall removemargin">
                ({filterResultsRaw?.totals?.orders || orderData?.length})
              </span>
            </div>

            <div
              className="xsmall removemargin cursor no-hover"
              onClick={() => {
                setFilterModals({
                  storeSales: {
                    display: false,
                  },
                  orders: {
                    display: true,
                  },
                });
              }}
            >
              {statFilters?.orders?.from || statFilters?.orders?.to ? "Custom" : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <DOrderFilterModal
                display={filterModals?.orders?.display}
                setDisplay={setFilterModals}
                setStatFilters={setStatFilters}
                statFilters={statFilters}
                runDateFilter={runDateFilter}
                setFilterResults={setFilterResults}
              />
            </div>
          </div>

          <div
            className="flex-row"
            style={{ justifyContent: "space-between", paddingRight: 10, gap: 50 }}
          >
            <p className="xsmall removemargin">
              Delivered Orders &nbsp;
              <span className="xsmall removemargin">
                (
                {filterResultsRaw?.totals?.delivered ||
                  orderData?.filter((e) => e.status === "completed")?.length}
                )
              </span>
            </p>
            <p className="xsmall removemargin" style={{ marginLeft: -27 }}>
              Pending Orders &nbsp;
              <span className="xsmall removemargin">
                (
                {filterResultsRaw?.totals?.pending ||
                  orderData?.filter((e) => e.status === "pending")?.length}
                )
              </span>
            </p>
            <p className="xsmall removemargin redText">
              Returns &nbsp;
              <span className="xsmall removemargin">
                (
                {filterResultsRaw?.totals?.returned ||
                  orderData?.filter((e) => e.status === "CANCELLED")?.length}
                )
              </span>
            </p>
          </div>

          <div
            className="flex-row"
            style={{ justifyContent: "space-between", paddingRight: 10, gap: 50 }}
          >
            <p className="big d-stat-text boldText removemargin">
              ₦
              {shortenNumber(filterResultsRaw?.ordersFilterResults?.delivered) ||
                (() => {
                  let total = 0;
                  orderData
                    ?.filter((e) => e.status === "completed")
                    ?.forEach((e) => {
                      total = total + e?.totalAmount;
                    });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>
            <p className="big d-stat-text boldText removemargin">
              ₦
              {shortenNumber(filterResultsRaw?.ordersFilterResults?.pending) ||
                (() => {
                  let total = 0;
                  orderData
                    ?.filter((e) => e.status === "pending")
                    ?.forEach((e) => {
                      total = total + e?.totalAmount;
                    });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>
            <p className="big d-stat-text boldText removemargin">
              ₦
              {shortenNumber(filterResultsRaw?.ordersFilterResults?.returned) ||
                (() => {
                  let total = 0;
                  orderData
                    ?.filter((e) => e.status === "CANCELLED")
                    ?.forEach((e) => {
                      total = total + e?.totalAmount;
                    });
                  return shortenNumber(total);
                })() ||
                "-"}
            </p>
          </div>
        </div>

        <div className="d-each-d-stat-container d-each-d-stat-container-three">
          <p className="small blackText removemargin">
            Delivered Orders
            <br></br>
            <span className="big removemargin blackText boldText">
              ₦
              {(() => {
                let total = 0;
                orderData
                  ?.filter((e) => e.status === "completed")
                  ?.forEach((e) => {
                    total = total + e?.totalAmount;
                  });
                return shortenNumber(total);
              })() || "-"}
            </span>
          </p>

          <div className={`circle-task-complete ${circleStatClass}`}>
            <p className="xsmall blackText removemargin">{circleStatValue}</p>
            <p className="xsmall blackText removemargin">Success Rate</p>
          </div>
        </div>
      </div>

      <div className="table-container">
        <div className="flex-row" style={{ marginBottom: 15 }}>
          <div className="search-input-box-wrapper">
            <CiSearch />
            <input
              placeholder="Search for a recent order (Order No. | Rider)"
              onChange={searchPlease}
              value={searchQuery}
            />
          </div>
        </div>
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <p className="big removemargin">Pending Orders</p>
        </div>
        <OrdersTable
          data={tablePendingData}
          limit={10}
          showPageNumber={true}
          page={page}
          setPage={setPage}
          dashboard={true}
          setScreen={ordersScreen?.setScreen}
        />
      </div>
    </div>
  );
};

export { Dashboard, filterResultsRaw };
