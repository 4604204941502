const columns = [
  {
    id: "o_checkbox",
    label: (
      <input
        id="o-checkbox-parent"
        type="checkbox"
        className="table-checkboxes o-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "_id", label: "Order No.", minWidth: 150 },
  { id: "rider", label: "Rider Name", minWidth: 150 },
  {
    id: "item_names",
    label: "Item Name",
    minWidth: 150,
    align: "left",
  },
  {
    id: "item_count",
    label: "Item Count",
    minWidth: 100,
    align: "center",
  },
  {
    id: "amount",
    label: "Amount (₦)",
    minWidth: 100,
    align: "left",
  },
  { id: "createdAt", label: "Date and Time", minWidth: 170 },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "left",
  },
];

function sortData(data) {
  const finalArray = data.map((e) => {
    return {
      o_checkbox: (
        <input
          data={e?._id}
          id="o-checkbox-children"
          type="checkbox"
          className="table-checkboxes o-table-checkboxes"
        />
      ),
      _id: e?._id,
      rider: e?.rider?.first_name ? e?.rider?.first_name?.slice(0, 30) + "..." : "-",
      item_names: e?.products[0]?.productID?.product_name?.slice(0, 30) + "...",
      item_count: e?.products?.length,
      amount: new Intl.NumberFormat("en-us").format(e.totalAmount),
      createdAt: (
        <span>
          {new Intl.DateTimeFormat("en-gb", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }).format(new Date(e?.createdAt))}
          <br></br>
          {new Intl.DateTimeFormat("en-gb", {
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(e?.createdAt))}
        </span>
      ),
      status: (
        <span
          style={{
            color: `${
              e?.status === "pending"
                ? "#FFB400"
                : e?.status === "processing"
                ? "#16B1FF"
                : e?.status === "shipped"
                ? "#0D6A99"
                : e?.status === "completed"
                ? "#347900"
                : "#FF4B4B"
            }`,
          }}
        >
          {e?.status === "pending"
            ? "pending"
            : e?.status === "processing"
            ? "packed"
            : e?.status === "shipped"
            ? "shipped"
            : e?.status === "completed"
            ? "delivered"
            : "returned"}
        </span>
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
