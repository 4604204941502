import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../orders.css";
import AppContext from "../../../context/app-context";
import { IoMdArrowBack } from "react-icons/io";
import { popup } from "../../../vanilla-functions/model";
import OrderItem from "./kids/order-item";

const OrderDetails = function ({ display, screen, setScreen }) {
  const [theOrder, setTheOrder] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [total, setTotal] = useState("");
  const { backendServer, setIsLoading, usersData, formatNumber, updateUsersData } =
    useContext(AppContext);

  async function getOrder() {
    try {
      if (!screen?.data) return;
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/order/vendor/get/${usersData?.vendor?._id}/${screen?.data}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("vmstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setTheOrder(returned?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching order details");
    }
  }

  useEffect(() => {
    getOrder();
  }, [screen]);

  useEffect(() => {
    setOrderItems(theOrder?.products?.map((e, i) => <OrderItem key={i} data={e} />));

    let total = 0;
    theOrder?.products?.forEach((e) => {
      total = total + e?.productID?.minimumPrice * e?.quantity;
    });
    setTotal(total);
  }, [theOrder]);

  async function markOrderAsPacked() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/order/processing/${screen?.data}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("vmstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setIsLoading(false);
      getOrder();
      updateUsersData();
      popup("Order marked as PACKED");
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching order details");
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p className="big removemargin">
          <IoMdArrowBack
            onClick={() => {
              setScreen({
                screen: "home",
                data: null,
              });
              setOrderItems([]);
              setTotal("");
            }}
            style={{ marginTop: -5 }}
            size={20}
            className="cursor"
          />{" "}
          &nbsp; Order #{screen?.data}
        </p>
      </div>

      <div className="flex-row" style={{ flexWrap: "wrap" }}>
        <div className="order-d-1">
          <p className="small removemargin blackText boldText" style={{ marginBottom: 20 }}>
            Order Items
          </p>

          <div className="order-d-items">{orderItems}</div>

          <div className="flex-row" style={{ justifyContent: "flex-end", marginTop: 20 }}>
            <p className="big removemargin boldText">
              Order Total: &nbsp;&nbsp;&nbsp; {formatNumber(total) || ""}
            </p>
          </div>
        </div>
        <div className="order-d-2">
          <div className="order-d-2-1">
            <p className="small removemargin blackText boldText" style={{ marginBottom: 20 }}>
              Order Items
            </p>

            <textarea
              placeholder="No notes from this customer"
              className="order-details-text-area"
              value={theOrder?.orderNotes}
              rows={50}
              cols={20}
              disabled={true}
            ></textarea>
          </div>
          {theOrder?.rider && (
            <div className="order-d-2-2">
              <p className="small removemargin blackText boldText" style={{ marginBottom: 15 }}>
                Rider Information
              </p>

              <p className="small">
                <span className="boldText blackText">Name:</span> &nbsp;&nbsp;&nbsp;{" "}
                {theOrder?.rider?.first_name} {theOrder?.rider?.last_name}
              </p>

              <p className="small removemargin">
                <span className="boldText blackText">Phone:</span> &nbsp;&nbsp;&nbsp;{" "}
                {theOrder?.rider?.mobile}
              </p>

              {/* <div className="flex-row cursor" style={{ justifyContent: "flex-end" }}>
                <p className="small removemargin redText">Report Rider</p>
              </div> */}
            </div>
          )}
        </div>
      </div>

      {theOrder?.status === "pending" && (
        <div className="flex-row" style={{ marginTop: 10 }}>
          <button onClick={markOrderAsPacked} className="themeBtn">
            Mark Order as Packed
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
