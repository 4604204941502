import React, { useState, useContext, useEffect } from "react";
import "../styles/index.css";
import "./side-nav.css";
import AppContext from "../context/app-context";

const SideNav = function ({ setSideNav, loggedIn }) {
  const [activeNav, setActiveNav] = useState(
    document.location.hash.toUpperCase()?.replace("#", "").trim() || "DASHBOARD"
  );
  const [logo, setLogo] = useState("");
  const { changeScreen, setIsLoading, usersData, getSignedAwsUrl } = useContext(AppContext);

  useEffect(() => {
    setSideNav({ setActiveNav });

    (async () => {
      if (usersData?.vendor?.logo)
        setLogo(await getSignedAwsUrl(usersData?.vendor?.logo, "others"));
      else setLogo("/logo.png");
    })();
  }, [loggedIn]);

  return (
    <div className="side-nav-container">
      <div className="side-nav-intro flex-row">
        <img src={logo} alt="Estility-Logo" className="estility-logo-side-nav" />
        <p className="big whiteText removemargin">
          {usersData?.vendor?.vendor_name.slice(0, 15)}...
        </p>
      </div>
      <div className="nav-items-overall">
        <div className="nav-items-container">
          <div
            onClick={() => {
              setActiveNav("DASHBOARD");
              changeScreen("DASHBOARD");
              document.location.hash = "Dashboard";
            }}
            className={`each-nav ${activeNav === "DASHBOARD" && "each-nav-active"}`}
          >
            <img src="/images/d-nav.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin">Dashboard</p>
          </div>

          <div
            onClick={() => {
              setActiveNav("ORDERS");
              changeScreen("ORDERS");
              document.location.hash = "Orders";
            }}
            className={`each-nav ${activeNav === "ORDERS" && "each-nav-active"}`}
          >
            <img src="/images/o-nav.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin">Orders</p>
          </div>

          <div
            onClick={() => {
              setActiveNav("PRODUCTS");
              changeScreen("PRODUCTS");
              document.location.hash = "Products";
            }}
            className={`each-nav ${activeNav === "PRODUCTS" && "each-nav-active"}`}
          >
            <img src="/images/p-nav.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin">Products</p>
          </div>

          <div
            onClick={() => {
              setActiveNav("WALLET");
              changeScreen("WALLET");
              document.location.hash = "Wallet";
            }}
            className={`each-nav ${activeNav === "WALLET" && "each-nav-active"}`}
          >
            <img src="/images/w-nav.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin">Wallet</p>
          </div>

          <div
            onClick={() => {
              setActiveNav("SUPPORT");
              changeScreen("SUPPORT");
              document.location.hash = "Support";
            }}
            className={`each-nav ${activeNav === "SUPPORT" && "each-nav-active"}`}
          >
            <img src="/images/s-nav.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin">Support</p>
          </div>
        </div>

        <div className="nav-items-container">
          <div
            onClick={() => {
              setActiveNav("SETTINGS");
              changeScreen("SETTINGS");
              document.location.hash = "Settings";
            }}
            className={`each-nav ${activeNav === "SETTINGS" && "each-nav-active"}`}
          >
            <img src="/images/se-nav.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin">Settings</p>
          </div>

          <div
            onClick={() => {
              setIsLoading(true);
              localStorage.removeItem("vmsusername");
              localStorage.removeItem("vmsemail");
              localStorage.removeItem("vmstoken");
              setTimeout(() => {
                document.location.reload();
              }, [1500]);
            }}
            className={`each-nav`}
          >
            <img src="/images/l-nav.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin">Log Out</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
