const columns = [
  {
    id: "w_checkbox",
    label: (
      <input
        id="w-checkbox-parent"
        type="checkbox"
        className="table-checkboxes w-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "initiated_on", label: "Date and Time", minWidth: 100 },
  { id: "_id", label: "Transaction ID", minWidth: 170 },
  {
    id: "transaction_type",
    label: "Transaction Type",
    minWidth: 100,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
  {
    id: "amount",
    label: "Amount (₦)",
    minWidth: 100,
    align: "left",
  },
  {
    id: "balance",
    label: "Balance",
    minWidth: 100,
    align: "left",
  },
];

function sortData(data) {
  const finalArray = data.map((e) => {
    return {
      w_checkbox: (
        <input
          data={e?._id}
          id="w-checkbox-children"
          type="checkbox"
          className="table-checkboxes w-table-checkboxes"
        />
      ),
      initiated_on: (
        <span>
          {new Intl.DateTimeFormat("en-gb", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }).format(new Date(e?.initiated_on))}
          <br></br>
          {new Intl.DateTimeFormat("en-gb", {
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(e?.initiated_on))}
        </span>
      ),
      _id: e?._id,
      transaction_type: e?.transaction_type,
      status: (
        <span
          style={{
            color: `${
              e?.status === "pending"
                ? "#FFB400"
                : e?.status === "completed"
                ? "#52AC10"
                : "#ff8680"
            }`,
          }}
        >
          {e?.status === "pending" ? "processing" : e?.status}
        </span>
      ),
      amount: new Intl.NumberFormat("en-us").format(e?.amount),
      balance: new Intl.NumberFormat("en-us").format(e?.balance),
    };
  });

  return finalArray;
}

export { sortData, columns };
