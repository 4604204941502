import React from "react";
import "../filter-modals.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { filterResultsRaw } from "../../../screens/dashboard/dashboard";

const DStoreFilterModal = ({
  display,
  setDisplay,
  setStatFilters,
  statFilters,
  runDateFilter,
  setFilterResults,
}) => {
  if (!display) return;
  return (
    <div className="absolute-filter-modal-container">
      <div className="close-filter-modal">
        <p className="small boldText">Date</p>
        <AiOutlineCloseCircle
          onClick={(e) => {
            e.stopPropagation();
            setDisplay({
              storeSales: {
                display: false,
              },
              orders: {
                display: false,
              },
            });
          }}
          className="cursor"
          size={20}
          color="#34204C"
        />
      </div>

      <div className="absolute-filter-modal-body">
        <div className="absolute-filter-modal-start">
          <p
            className="small removemargin cursor"
            onClick={(e) => {
              e.stopPropagation();
              runDateFilter("store-sales", null, null, false, true);
            }}
          >
            Today
          </p>
          <p
            className="small removemargin cursor"
            onClick={(e) => {
              e.stopPropagation();
              runDateFilter("store-sales", null, null, true, false);
            }}
          >
            Yesterday
          </p>
        </div>

        <div className="absolute-filter-modal-date">
          <div>
            <p className="xsmall removemargin">From</p>
            <input
              className="absolute-filter-date-in"
              type="date"
              value={statFilters?.storeSales?.from}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setStatFilters({
                  storeSales: {
                    from: e.target.value,
                    to: statFilters.storeSales?.to,
                  },
                  orders: statFilters.orders,
                });
              }}
            />
          </div>
          <div>
            <p className="xsmall removemargin">To</p>
            <input
              className="absolute-filter-date-in"
              type="date"
              value={statFilters?.storeSales?.to}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setStatFilters({
                  storeSales: {
                    to: e.target.value,
                    from: statFilters.storeSales.from,
                  },
                  orders: statFilters.orders,
                });
              }}
            />
          </div>
        </div>

        <div className="absolute-filter-modal-actions">
          <p
            className="small removemargin cursor"
            onClick={() => {
              setStatFilters({
                storeSales: {
                  from: "",
                  to: "",
                },
                orders: statFilters.orders,
              });
              setFilterResults({});

              filterResultsRaw.storeSalesFilterResults = "";
              filterResultsRaw.totals.storeSales = "";
            }}
          >
            Clear
          </p>
          <button
            className="themeBtn"
            onClick={(e) => {
              e.stopPropagation();
              runDateFilter(
                "store-sales",
                statFilters?.storeSales?.from,
                statFilters?.storeSales?.to
              );
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default DStoreFilterModal;
