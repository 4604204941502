import React from "react";
import "../filter-modals.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { filterResultsRaw, statFiltersRaw } from "../../../screens/wallet/wallet";

const PaidWalletFilter = ({
  display,
  setDisplay,
  setStatFilters,
  runDateFilter,
  setFilterResults,
}) => {
  if (!display) return;
  return (
    <div className="absolute-filter-modal-container">
      <div className="close-filter-modal">
        <p className="small boldText">Date</p>
        <AiOutlineCloseCircle
          onClick={(e) => {
            e.stopPropagation();
            setDisplay({
              paid: false,
              pending: false,
              returned: false,
            });
          }}
          className="cursor"
          size={20}
          color="#34204C"
        />
      </div>

      <div className="absolute-filter-modal-body">
        <div className="absolute-filter-modal-start">
          <p
            className="small removemargin cursor"
            onClick={(e) => {
              e.stopPropagation();
              runDateFilter("paid", null, null, false, true);
            }}
          >
            Today
          </p>
          <p
            className="small removemargin cursor"
            onClick={(e) => {
              e.stopPropagation();
              runDateFilter("paid", null, null, true, false);
            }}
          >
            Yesterday
          </p>
        </div>

        <div className="absolute-filter-modal-date">
          <div>
            <p className="xsmall removemargin">From</p>
            <input
              className="absolute-filter-date-in"
              type="date"
              value={statFiltersRaw?.paid?.from}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setStatFilters({});
                statFiltersRaw.paid.from = e.target.value;
              }}
            />
          </div>
          <div>
            <p className="xsmall removemargin">To</p>
            <input
              className="absolute-filter-date-in"
              type="date"
              value={statFiltersRaw?.paid?.to}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setStatFilters({});
                statFiltersRaw.paid.to = e.target.value;
              }}
            />
          </div>
        </div>

        <div className="absolute-filter-modal-actions">
          <p
            className="small removemargin cursor"
            onClick={() => {
              filterResultsRaw.walletFilterResults.paid = "";
              filterResultsRaw.totals.paid = "";
              statFiltersRaw.paid.from = "";
              statFiltersRaw.paid.to = "";
              setFilterResults({});
            }}
          >
            Clear
          </p>
          <button
            className="themeBtn"
            onClick={(e) => {
              e.stopPropagation();
              runDateFilter("paid", statFiltersRaw?.paid?.from, statFiltersRaw?.paid?.to);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaidWalletFilter;
